import React from "react"
import styled from 'styled-components'
import { palette } from '@theme'
import { Waypoint } from 'react-waypoint'
import { GridRuleProps } from '@types'
import { useGridHelper } from '@utils'


const GridRule = ({ ruleType, gapHeight, sticky, stickyPosition, screenSnap, ...props }: GridRuleProps) => {
  const { gridHelperEnabled } = useGridHelper()
  const [inView, setInView] = React.useState(false)
  const enter = () => setInView(true)
  
  return (
    <Wrapper {...{ gridHelperEnabled, screenSnap }} {...props} >
      <Rule {...{ ruleType, sticky, gapHeight, stickyPosition, inView }} >
        <Waypoint
          onEnter={() => enter()} 
          scrollableAncestor={typeof window !== 'undefined' ? window : undefined}
          bottomOffset="15%"
        />
      </Rule>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  gridHelperEnabled: boolean
  hide?: 'none' | 'mobile' | 'computer'
  mobileColumns?: number, 
  mobileColumnStart?: number,
  rows?: number,
  columns?: number, 
  columnStart?: number,
  screenSnap?: boolean,
}>`
  position: relative;
  z-index: 0;
  display: block;
  ${props => props.hide === 'mobile' && `
    @media only screen and (max-width: 743px) {
      display: none;
    }
  `}
  ${props => props.hide === 'computer' && `
    @media only screen and (min-width: 744px) {
      display: none;
    }
  `}
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 6};
  
  @media only screen and (min-width: 744px) {
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 12};
    grid-row-end: span ${props => props.rows || 1};
  }
  
  ${props => props.gridHelperEnabled && `outline: 1px dashed red;`}
  ${props => props.screenSnap && `scroll-snap-align: start;`}
`

const Rule = styled.div<{
  ruleType?: 'rule' | 'leftArrow' | 'rightArrow' | 'gap' | '', 
  gapHeight?: number,
  sticky?: boolean,
  stickyPosition?: number,
  inView: boolean,
}>`
  position: ${props => props.sticky ? 'sticky' : 'relative'};
  ${props => props.sticky ? `top:${props.stickyPosition || 0}vh;` : ''}

  height: ${props => props.ruleType === 'gap' && props.gapHeight !== 9999 ? `${props.gapHeight}vw}`
    : props.ruleType === 'gap' && props.gapHeight === 9999 ? `100vh}`
    : '1px'
  };
  
  ${props => (props.ruleType === 'leftArrow' || props.ruleType === 'rightArrow') && `
    height: 23px;
    @media only screen and (min-width: 744px) {
      height: 47px;
    }
    @media only screen and (min-width: 1100px) {
      height: 47px;
    }
  `}
  
  ${props => props.ruleType === 'gap' && props.gapHeight === 9999 && `
    max-height: 10vh;
    @media only screen and (min-width: 744px) {
      max-height: 100vh;
    }
  `}
  width: 100%;
  line-height: 0;
  
  ${props => props.ruleType !== 'gap' && `
    background: linear-gradient(
      to right, 
      ${palette.teak}, 
      ${palette.teak} 50%, 
      rgba(188, 154, 113, 0) 50%, 
      rgba(188, 154, 113, 0) 100%
    );
    background-size: 200% 100%;
    background-position: 100% 0%;
  `}

  @keyframes Rule { 
      0%{
        background-position:100% 0%
      }
      100%{
        background-position:0% 0%
      }
  }
  ${props => props.inView && props.ruleType !== 'gap' && `
    animation: Rule 1s ease-in-out;
  `}
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  
  transform: rotateZ(${props => props.ruleType === 'leftArrow' ? '180deg' : '0deg'});
  
  ${props => (props.ruleType === 'leftArrow' || props.ruleType === 'rightArrow') && `
    mask-image: url('/images/arrow.svg');
    mask-repeat: no-repeat;
    mask-position: right center;
    mask-size: auto 23px;
    @media only screen and (min-width: 744px) {
      mask-size: auto 32px;
    }
    @media only screen and (min-width: 1100px) {
      mask-size: auto 47px;
    }
    z-index: 999999;
    transform-style: preserve-3d;
    backface-visibility: hidden;
  `}

`

export default GridRule