import React from "react"
import { LinkMask } from '@components/atoms'
import { GridItemWrapper } from '@components/molecules'
import { GridItemFX } from './'
import { GridItemVectorProps } from '@types'
import { useGridHelper } from '@utils'
import styled from 'styled-components'


const GridItemVector = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  className,
  hasFX,
  parallaxSpeed,
  screenSnap,
  ...props
}: GridItemVectorProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemVector ) return <></>
  const {
    gridItemVector: {
      svgFile,
    },
  } = gridItemTypeSwitch
  
  const hoverColor = (props.extraHomeFields?.backgroundColor) ? props.extraHomeFields.backgroundColor.rgb : undefined

  return (
    <GridItemWrapper {...{ gridHelperEnabled, fullBleed, screenSnap, className }} {...layout} >
      <LinkMask {...{link, post}} />
      <GridItemFX {...{ hasFX }} parallaxSpeed={parallaxSpeed || 1} >
        <ImageWrapper className="zoomable" {...{ hoverColor }} >
          <img src={svgFile.asset.url} />
        </ImageWrapper>
      </GridItemFX>
    </GridItemWrapper>
  )
}


const ImageWrapper = styled.div`
  overflow: hidden;
  line-height: 0;
  position: relative;
  img {
    width: 100%;
  }
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: box-shadow 1.5s ease-in-out;
    box-shadow: inset 0 0 100px rgba(0, 0, 0, 0);
  }
`


export default GridItemVector