import React from "react"
import styled from 'styled-components'
import { H3 } from '@components/atoms/BlockRenderer'


const BackToTop = () => {
  const scrollToTop = () => window.scrollTo({behavior: 'smooth', left: 0, top: 0})
  return (
    <Wrapper onClick={() => scrollToTop()} className="backToTop" >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 19.0002V4.75024" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.25 11.5002L12 4.75024L18.75 11.5002" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </Wrapper>
  )
}


const Wrapper = styled(props => <H3 {...props} />)`
  position: relative;
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    text-align: left;
    grid-column: 1 / span 3;
  }
  @media only screen and (min-width: 1100px) {
    grid-column: 1 / span 2;
  }
  cursor: pointer;
  svg {
    vertical-align: -20%;
    margin-left: 5px;
  }
  &:before {
    @media only screen and (min-width: 325px) {
      content: 'Back to top';
    }
  }
`


export default BackToTop