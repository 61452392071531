import styled from 'styled-components'

export default styled.div<{ 
  fullBleed?: boolean,
  hide?: 'none' | 'mobile' | 'computer'
  mobileColumns?: number, 
  mobileColumnStart?: number,
  columns?: number, 
  columnStart?: number,
  rows?: number,
  rowStart?: number,
  hidden?: boolean,
  vAlign?: 'top' | 'center' | 'bottom',
  gridHelperEnabled: boolean,
  screenSnap?: boolean,
  margin?: string,
  sticky?: boolean
  stickyPosition?: number
}>`
  ${props => props.hide === 'mobile' && `
    @media only screen and (max-width: 743px) {
      display: none;
    }
  `}
  ${props => props.hide === 'computer' && `
    @media only screen and (min-width: 744px) {
      display: none;
    }
  `}
  position: relative;
  
  ${props => props.sticky && `
    > * {
      position: sticky;
      top: ${props.stickyPosition || 0}vh;
      > *:last-of-type {
        margin-bottom: 0 !important;
      }      
    }  
  `};
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 6};
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  justify-content: ${props => props.vAlign == 'bottom' ? 'flex-end' : props.vAlign == 'center' ? 'center' : 'flex-start'};
  height: 100%;

  ${props => (props.margin !== undefined && props.margin !== null)
    ? props.margin.split(' ').length === 1
      ? `margin: ${parseInt(props.margin)}vw 0 ${parseInt(props.margin)}vw 0;`
      : `margin: ${parseInt(props.margin!.split(' ')[0])}vw 0 ${parseInt(props.margin!.split(' ')[1])}vw 0;`
    : ''
  }
  
  ${props => props.rowStart && `
    grid-row-start: ${props.rowStart};
  `}

  @media only screen and (min-width: 744px) {
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 12};
    ${props => props.rows && props.rows !== 1 && `grid-row-end: span ${props.rows};`}
    ${props => (props.margin !== undefined && props.margin !== null)
      ? props.margin.split(' ').length === 1
        ? `margin: ${parseInt(props.margin) * 1.5}vw 0 ${parseInt(props.margin) * 1.5}vw 0;`
        : `margin: ${parseInt(props.margin!.split(' ')[0]) * 1.5}vw 0 ${parseInt(props.margin!.split(' ')[1]) * 1.5}vw 0;`
      : ''
    }
  }
  
  align-self: start;
  ${props => props.gridHelperEnabled && `
    outline: 1px dashed red;
  `}
  
  ${props => props.fullBleed && `
    width: 100%;
    grid-column-start: 1 !important;
    grid-column-end: span 6;
    margin-left: -20px;
    @media only screen and (min-width: 744px) {
      margin-left: -30px;
      grid-column-end: span 12;
    }
  `}

  ${props => props.screenSnap && `scroll-snap-align: start;`}

`
