import React from "react"
import styled from 'styled-components'
import { ScrollerArrow } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from '@components/molecules'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from '@utils'
import { GridItemStripProps} from '@types'
import { useGridHelper } from '@utils'


const GridItemScrollStrip = ({ 
  insideGrid,
  stripItems,
  children,
  className,
}: { insideGrid?: boolean, children?: React.ReactNode } & GridItemStripProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const { width } = useWindowSize()
  const scroller = React.useRef<HTMLDivElement>(null)
  const strip = React.useRef<HTMLDivElement>(null)
  const [stripPosition, setStripPosition] = React.useState({x: 0, y: 0})
  useScrollPosition(
    ({ currPos }) => setStripPosition(currPos), // effect callback
    [setStripPosition], // dependencies
    // @ts-ignore this hook is weird about typescript 
    strip, // position of element
    false, // use window instead of body.getBoundingClientRect
    10, // performance debounce
    scroller, // element wrapper (actual scroll)
  )

  /* We are constricting the user options for setting up the grid.
   * Normally they are able to set the width and starting column.
   * They can still set the width, but we are just putting them in an unbroken row without gaps now.
   */
  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>(0)
  React.useEffect(() => {
    setTimeout(() => setGridColumnWidth((document.getElementById('gridColumnWidth')?.offsetWidth || 0)), 50)
  }, [width])

  const scroll = (direction: 'left' | 'right') => {
    const w = window.innerWidth
    const x = direction === 'left' ? stripPosition.x - w : stripPosition.x + w
    scroller.current?.scrollTo({behavior: 'smooth', left: x, top: 0})
  }
  
  const [scrollerWidth, setScrollerWidth] = React.useState(9999)
  React.useEffect(() => {
    setTimeout(() => setScrollerWidth(strip.current?.scrollWidth || 9999), 100)
  }, [width, strip.current])
  
  const [leftArrowVisible, setLeftArrowVisible] = React.useState(false)
  const [rightArrowVisible, setRightArrowVisible] = React.useState(true)
  React.useEffect(() => {
    setLeftArrowVisible(stripPosition.x > 0)
    setRightArrowVisible(stripPosition.x < scrollerWidth - width)
  }, [stripPosition, scrollerWidth, width])

  return (
    <GridItemWrapper {...{ gridHelperEnabled }} >
      <Wrapper {...{ insideGrid, className }}>
        <ScrollerArrow 
          active={leftArrowVisible} 
          onClick={() => scroll('left')}
          left
        />
        <ScrollerArrow 
          active={rightArrowVisible}
          onClick={() => scroll('right')}
        />          
        <Scroller ref={scroller} >
          <InteriorStrip {...{ gridColumnWidth }} ref={strip}  >
            {children && React.Children.map(children, (child) => React.cloneElement(child as React.ReactElement, { gridColumnWidth }))}
            {stripItems && stripItems.map((item, i) => (
              <ScrollStripGridItem 
                {...item} 
                _type="gridItem"
                key={i} 
                columns={item.layout?.layout?.columns} 
                mobileColumns={item.layout?.layout?.mobileColumns}
                {...{ gridColumnWidth }}
              />
            ))}
          </InteriorStrip>
        </Scroller>
      </Wrapper>
    </GridItemWrapper>
  )
}



const Wrapper = styled.div<{ insideGrid?: boolean, }>`
  position: relative;
  width: calc(100% + 40px);
  margin-left: -20px;
  @media only screen and (min-width: 744px) {
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  @media only screen and (min-width: 1152px) {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
  
  @media only screen and (min-width: 1440px) {
    width: calc(100% + 5.55vw);
    margin-left: -2.775vw;
  }

  ${props => props.insideGrid && `
    margin-left: -20px;
    transition: background-color 0.15s ease-in-out;
    padding: 30px 0;
    @media only screen and (min-width: 744px) {
      padding: 90px 0;    
    }
    @media only screen and (min-width: 1024px) {
      padding: 120px 0;
    }
  `}
`


const Scroller = styled.div`
  position: relative;
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  @media only screen and (min-width: 744px) {
    width: 100vw;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display:none;
    width: 0;
    height: 0;
  }
`


const InteriorStrip = styled.div<{ 
  gridColumnWidth: number, 
}>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(99, ${props => props.gridColumnWidth}px);
  grid-column-gap: 20px;
  grid-row-gap: 25px;
  padding-left: 20px;

  /* stupid hack, overflow doesn't have padding on the right */
  > *:last-of-type {
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      width: 20px;
      height: 1px;

      @media only screen and (min-width: 744px) {
        right: -60px;
        width: 60px;
      }
      
      @media only screen and (min-width: 1152px) {
        right: -80px;
        width: 80px;
      }
      
      @media only screen and (min-width: 1440px) {
        right: -2.775vw;
        width: 2.775vw;
      }
    }
  }

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(99, ${props => props.gridColumnWidth}px);
    padding: 0px 0px 0 ${props => props.gridColumnWidth * 2 + 70}px;
  }
  
  @media only screen and (min-width: 1152px) {
    padding: 0px 0px 0 ${props => props.gridColumnWidth * 2 + 80}px;
  }

  @media only screen and (min-width: 1440px) {
    padding: 0px 0px 0 calc(${props => props.gridColumnWidth * 2}px + (2 * 1.3875vw) + 2.775vw);
  }
`


export const ScrollStripGridItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-column-end: span ${props => props.mobileColumns};
  @media only screen and (min-width: 744px) {
    grid-column-end: span ${props => props.columns};
  }
  
  /* override "fade up" animation, it messes up the scroller */
  img, video {
    transform: none !important;
  }
      
`


export default GridItemScrollStrip