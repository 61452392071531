import React from "react"
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { ColorShifterProps } from '@types'
import { useDisplayModeDispatch, useGridHelper } from '@utils'
import { palette } from '@theme'


const ColorShifter = ({ gridSnap, toBackgroundColor, toForegroundColor, fromBackgroundColor, fromForegroundColor, ...props }: ColorShifterProps): React.ReactElement => {
  const displayModeDispatch = useDisplayModeDispatch()
  const { gridHelperEnabled } = useGridHelper()
  const enter = (_d: Waypoint.CallbackArgs) => {
    displayModeDispatch({ 
      type: 'setDisplayMode', 
      payload: {
        foregroundColor: `rgb(${toForegroundColor.rgb.r}, ${toForegroundColor.rgb.g}, ${toForegroundColor.rgb.b})`,
        backgroundColor: `rgb(${toBackgroundColor.rgb.r}, ${toBackgroundColor.rgb.g}, ${toBackgroundColor.rgb.b})`,
      }
    })
  }
  const leave = (d: Waypoint.CallbackArgs) => {
    if( d.currentPosition === 'above' ) return
    displayModeDispatch({ 
      type: 'setDisplayMode', 
      payload: {
        backgroundColor: fromBackgroundColor ? `rgb(${fromBackgroundColor.rgb.r}, ${fromBackgroundColor.rgb.g}, ${fromBackgroundColor.rgb.b})` : palette.white,
        foregroundColor: fromForegroundColor ? `rgb(${fromForegroundColor.rgb.r}, ${fromForegroundColor.rgb.g}, ${fromForegroundColor.rgb.b})` : palette.thunder,
      }
    })
  }
  return (
    <Wrapper {...props} {...{ gridHelperEnabled }} >
      <Waypoint 
        onEnter={(d) => enter(d)} 
        onLeave={(d) => leave(d)} 
        scrollableAncestor={!gridSnap && typeof window !== 'undefined' ? window : undefined}
        bottomOffset="35%"
      />
    </Wrapper>
  )
}


const Wrapper = styled.div<{ gridHelperEnabled: boolean }>`
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
  ${props => props.gridHelperEnabled && `border-top: 1px dashed green;`}
  @media only screen and (min-width: 744px) {
    grid-column-end: span 12;
  }
`


export default ColorShifter