import React from "react"
import styled from 'styled-components'
import { BlockRenderer, LinkMask, ToolTip } from '@components/atoms'
import { H3 } from '@components/atoms/BlockRenderer'
import { GridItemFX } from './'
import { GridItemCaptionProps } from '@types'
import { ChildrenWrapper } from './GridItem'
import { GridItemWrapper } from '@components/molecules'
import { useGridHelper } from '@utils'
import { palette } from '@theme'
import { isTouchDevice } from '@utils'


const GridItemCaption = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  hasFX,
  parallaxSpeed,
  screenSnap,
  className,
}: GridItemCaptionProps & { className?: string }): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemCaption ) return <></>  
  const {
    gridItemCaption: {
      _rawCaption,
      textAlign,
      hasHoverText,
      _rawHoverText,
      hoverIllustration,
      collapsible,
      collapsibleTrigger,
    },
  } = gridItemTypeSwitch
  
  const [visible, setVisible] = React.useState(false)
  const [collapsed, setCollapsed] = React.useState(collapsible)

  return (
    <StyledGridItemWrapper 
      {...{ fullBleed, gridHelperEnabled, screenSnap, collapsible, className }} 
      {...layout} 
      onMouseEnter={() => setVisible(!!hasHoverText ? true : false)} 
      onMouseLeave={() => setVisible(false)}
      onClick={() => !isTouchDevice() && setVisible(!visible)}
    >
      <LinkMask {...{link, post}} />
      <GridItemFX
        {...{ hasFX }}
        parallaxSpeed={parallaxSpeed || 1} 
      >
        <>
          {collapsible && (
            <Trigger onClick={() => setCollapsed(!collapsed)} active={!collapsed} >{collapsibleTrigger}</Trigger>
          )}
          <ItemCaption {...{ textAlign, hasHoverText, collapsed, collapsible, }}>
            <BlockRenderer>{_rawCaption}</BlockRenderer> 
          </ItemCaption>
        </>
      </GridItemFX>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
      {hasHoverText && (
        <ToolTip {...{ visible, hoverIllustration, _rawHoverText }} />
      )}
    </StyledGridItemWrapper>
  )
}


const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)<{
  collapsible?: boolean,
}>`
  ${props => props.collapsible && `
    border-bottom: 1px solid ${palette.teak};
  `}
`


const ItemCaption = styled.div<{
  textAlign?: 'left' | 'right' | 'center' | 'justify',
  hasHoverText?: boolean,
  collapsible?: boolean,
  collapsed?: boolean,
}>`
  text-align: ${props => props.textAlign || 'left'};
  > *:last-child {
    margin: 0;
    ${props => props.collapsible && `
      padding-bottom: 1em;
    `}
  }
  ${props => props.hasHoverText && `
    transition: opacity 0.15s ease-in-out;
    &:hover {
      opacity: 0.5;
    }
  `}
  ${props => props.collapsible && `
    box-sizing: border-box;
    max-height: ${props.collapsed ? 0 : 100}px;
    will-change: max-height;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
  `}
`


const Trigger = styled(props => <H3 {...props} />)<{ active: boolean }>`
  cursor: pointer;
  position: relative;
  &:after {
    content: ' ';
    position: absolute;
    right: 0px;
    top: 9px;
    width: 19px;
    height: 1px;
    background: ${palette.teak};
  }
  &:before {
    content: ' ';
    position: absolute;
    top: 0px;
    right: 9px;
    width: 1px;
    height: 19px;
    background: ${palette.teak};
    transition: transform 0.5s ease-in-out;
    transform: scaleY(${props => props.active ? 0 : 1});
    transform-origin: center center;
  }
`


export default GridItemCaption