import React from "react"
import styled from 'styled-components'
import { Link, Picture } from '@components/atoms'
import { H2 } from '@components/atoms/BlockRenderer'
import { LinkMaskStyle } from '@components/atoms/LinkMask'
import { PostTitle, Subhead } from '../../pages/index'


export interface RelatedProps {
  title: string
  slug: {
    current: string
  }
  shareImage?: {
    asset: {
      url: string
      metadata : {
        dimensions : {
          width: number
          height: number
          aspectRatio: number
        }
      }
    }
  }
}


const RelatedPosts = ({nextPost, previousPost}: {nextPost?: RelatedProps, previousPost?: RelatedProps}) => {
  if( !previousPost && !nextPost ) return <></>
  return (
    <>
      <Header>Other Work</Header>
      {previousPost && <RelatedPost {...previousPost} left />}
      {nextPost && <RelatedPost {...nextPost} />}
    </>
  )
}


const Header = styled(props => <H2 {...props} />)`
  margin-top: 4em;
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 3;
    grid-column-end: span 10;
  }
`


const RelatedPost = ({
  slug,
  shareImage,
  title,
  left,
  active,
}: RelatedProps & RelatedPostWrapperProps) => (
  <RelatedPostWrapper {...{ left, active }} className={active ? 'active' : ''} >
    <LinkMask to={`/${slug.current}`} />
    <Picture src={shareImage?.asset.url!} alt={title} />
    <PostTitle className="linkHighlight" >{title}</PostTitle>
    <Subhead>View Case Study</Subhead>
  </RelatedPostWrapper>
)


interface RelatedPostWrapperProps {
  left?: boolean
  active?: boolean
}


const RelatedPostWrapper = styled.div<RelatedPostWrapperProps>`
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: ${props => props.left ? 3 : 7};
    grid-column-end: span 4;
  }
`


const LinkMask = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
`


export default RelatedPosts