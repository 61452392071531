import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(
    graphql`
      query SocialLinksQuery {
        sanitySiteSettings {
          socialLinks {
            networkName
            link
          }
        }
      }
    `
  )
  
  return data.sanitySiteSettings.socialLinks
}