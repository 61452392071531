import React from "react"
import styled from 'styled-components'
import { Picture, Grid, BlockRenderer, } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { GridItemCaptionProps, GridItemImageProps, GridItemVideoProps } from '@types'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import HeroKenBurns from './HeroKenBurns'


export interface HeroProps {
  heroType?: 'none' | 'image' | 'video' | 'text'
  heroTextConditional?: {
    _rawHeroText?: Array<any>
  }
  heroHeight?: number
  heroImageConditional?: {
    heroImage: {
      asset: {
        url: string
        metadata : {
          dimensions : {
            width: number
            height: number
            aspectRatio: number
          }
        }
      }
    }
    bleed?: 'full bleed' | 'right bleed'
  }
  heroVideoConditional?: {
    heroVideo: {
      asset: {
        url: string
      }
    }
    bleed?: 'full bleed' | 'right bleed'
  }
  heroFX?: 'none' | 'parallax' | 'kenBurns'
  heroParallaxConditional?: {
    gridItems: Array<GridItemCaptionProps | GridItemImageProps | GridItemVideoProps>
  }
  children?: any
}


const Hero = ({
  heroType,
  heroHeight,
  heroImageConditional,
  heroTextConditional,
  heroVideoConditional,
  heroParallaxConditional,
  heroFX,
  ...props
}: HeroProps) => {
  if(!heroType || heroType === 'none') return <></>
  if(heroFX === 'kenBurns') return (
    <HeroKenBurns {...{ heroType, heroHeight, heroImageConditional, heroTextConditional, heroVideoConditional, heroParallaxConditional, heroFX, }} {...props} />
  )
  const bleed = (heroType === 'image') ? heroImageConditional!.bleed : (heroType === 'video') ? heroVideoConditional!.bleed : 'full bleed'
  const [offset, setOffset] = React.useState<number>(0)
  useScrollPosition(
    ({ currPos }) => {
      setOffset(currPos.y)
    }, // effect callback
    [setOffset], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )

  return (
    <Wrapper {...{ bleed, heroFX, heroHeight }} {...props} >
      {heroType === 'image' && heroImageConditional && (
        <HeroImage 
          src={heroImageConditional?.heroImage.asset.url}
          dimensions={heroImageConditional?.heroImage.asset.metadata.dimensions}
          {...{ heroFX }}
        />
      )}
      {heroType === 'video' && heroVideoConditional && (
        <HeroVideo 
          autoPlay muted loop playsInline 
          {...{ heroFX }}
        >
          <source src={heroVideoConditional?.heroVideo.asset.url} />
        </HeroVideo>
      )}
      {heroType === 'text' && heroTextConditional && (
        <HeroText {...{ heroFX }} >
          <BlockRenderer>{heroTextConditional._rawHeroText}</BlockRenderer>
        </HeroText>
      )}

      {heroFX && heroParallaxConditional && (
        <HeroFxGrid>
          {heroParallaxConditional.gridItems.filter(p => !!p).map(item => (
            <GridItem key={item._key} {...item} {...{ offset }} hasFX parallaxSpeed={item.parallaxSpeed} />
          ))}
        </HeroFxGrid>
      )}
    </Wrapper>
  )
}


const HeroFxGrid = styled(props => <Grid {...props} />)`
  position: absolute;
  top: 55vh;
  width: 100%;
  padding: 0 20px;

  @media only screen and (min-width: 744px) {
    padding: 0 30px;
  }
  
  @media only screen and (min-width: 1152px) {
    padding: 0 40px;
  }
  
  @media only screen and (min-width: 1440px) {
    padding: 0 2.775vw;
  }
`


/* bleed calculation:
    left margin +
    two grid gaps +
    calc(( 100vw - both margins - 11 grid gaps ) * .1666 (two columns))
*/
const Wrapper = styled.div<{
  bleed?: 'full bleed' | 'right bleed',
  heroFX?: 'none' | 'parallax' | 'kenBurns',
  heroHeight?: number,
}>`
  position: relative;
  ${props => props.heroFX === 'parallax' && `height: calc(180vh + ${props.heroHeight || 100}vw);`}
  @media only screen and (min-width: 744px) {
    ${props => props.heroFX === 'parallax' && `height: calc(100vh + ${props.heroHeight || 100}vw);`}
  }
  ${props => props.bleed === 'right bleed' && `
    margin-left: 20px;
    @media only screen and (min-width: 744px) {
      margin-left: calc(
        30px + 
        40px + 
        calc((100vw - 60px - 220px) * .16666)
      );
    }
    @media only screen and (min-width: 1152px) {
      margin-left: calc(
        40px + 
        40px + 
        calc((100vw - 80px - 220px) * .16666)      
      );
    }
    @media only screen and (min-width: 1440px) {
      margin-left: calc(
        2.775vw + 
        40px + 
        calc((100vw - 5.55vw - 220px) * .16666)
      );
    }
  `}
`


const HeroImage = styled(props => <Picture {...props} />)<{ heroFX?: 'none' | 'parallax' | 'kenBurns' }>`
  ${props => props.heroFX === 'parallax' && `
    position: sticky;
    z-index: 10;
    left: 0;
    top: 0;
  `}
  width: 100%;
  height: auto;
`


const HeroVideo = styled.video<{ heroFX?: 'none' | 'parallax' | 'kenBurns' }>`
  ${props => props.heroFX === 'parallax' && `
    position: sticky;
    z-index: 10;
    left: 0;
    top: 0;
  `}
  width: 100%;
  height: auto;
`


const HeroText = styled.div<{ heroFX?: 'none' | 'parallax' | 'kenBurns' }>`
  ${props => props.heroFX === 'parallax' && `
    position: sticky;
    z-index: 10;
    left: 0;
    top: 0;
  `}
  max-height: 100vh;
  width: 100%;
  height: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 20px;
  @media only screen and (min-width: 744px) {
    padding: 0 20vw;
  }
  text-align: center;
`





export default Hero