import React from "react"
import styled from 'styled-components'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { BlockRenderer } from '@components/atoms'
import { palette } from '@theme'
import { isTouchDevice } from '@utils'


interface ToolTipProps {
  visible: boolean
  _rawHoverText?: any
  hoverIllustration?: {
    asset: {
      url: string
    }
  }
}


const ToolTip = ({ visible, hoverIllustration, _rawHoverText }: ToolTipProps) => {
  const touchStyle = {
    display: 'block',
    position: 'relative',
    top: 0,
    left: 0,
  }
  return (
    <MouseTooltip {...{ visible }} style={isTouchDevice() ? touchStyle : {} }>
      <HoverBlock {...{ visible, hoverIllustration, _rawHoverText }} isTouchDevice={isTouchDevice()} >
        {!!_rawHoverText && (
          <BlockRenderer>{_rawHoverText}</BlockRenderer>
        )}
      </HoverBlock>
    </MouseTooltip>
  )
}


const HoverBlock = styled.div<ToolTipProps & { isTouchDevice: boolean }>`
  pointer-events: none;
  ${props => props.isTouchDevice ? `
    max-height: ${props.visible ? 400 : 0}px;
    min-height: ${props.visible ? '130px' : 'none'};
    transition: max-height 0.15s ease-in-out;
    overflow: hidden;
    > * {
      padding-top: 1em;
    }

    ${props.hoverIllustration && `
      background-image: url(${props.hoverIllustration.asset.url});
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 120px auto;
      padding-left: 140px;
    `}

  ` : `
    position: absolute;
    bottom: 2vw;
    left: 2vw;
    z-index: 99999;
    width: 30vw;
    padding: 2vw;
    pointer-events: none;
    background: ${palette.thunder};
    color: white;
    ${props.hoverIllustration && `
      background-image: url(${props.hoverIllustration.asset.url});
      background-repeat: no-repeat;
      background-position: right 2vw top 2vw;
      background-size: auto 8vw;
      padding: 10vw 2vw 2vw 2vw;
    `}
    
    ${!props._rawHoverText && `
      background-color: transparent;
      width: 24vw;
      height: 16vw;
      padding: 0;
      background-size: contain;
    `}
  `}
`


export default ToolTip