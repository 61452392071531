import React from "react"
import styled from 'styled-components'
import { ScrollerArrow } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from '@components/molecules'
import { useWindowSize, useGridHelper } from '@utils'
import { GridItemStripProps} from '@types'


const GridItemFocusStrip = ({ 
  stripItems,
  className,
  children,
}: GridItemStripProps): React.ReactElement => {
  const { width } = useWindowSize()
  const { gridHelperEnabled } = useGridHelper()

  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>(1)
  React.useEffect(() => {
    setTimeout(() => setGridColumnWidth(document.getElementById('gridColumnWidth')?.offsetWidth ?? 0), 50)
  }, [width])

  const [focusIndex, setFocusIndex] = React.useState<number>(0)
  const shift = (direction: 'left' | 'right') => {
    if( direction === 'left' && focusIndex > 0 ) {
      setFocusIndex(focusIndex - 1)
    } else if( direction === 'right' && stripItems && focusIndex < stripItems.length - 1 ) {
      setFocusIndex(focusIndex + 1)
    }
  }
  
  if(!stripItems) return <></>
  
  return (
    <GridItemWrapper {...{ gridHelperEnabled }} >
      <Wrapper {...{ className }}>
        <ScrollerArrow 
          active={focusIndex >= 1} 
          onClick={() => shift('left')}
          {...{ gridColumnWidth }}
          left
        />
        <ScrollerArrow 
          active={focusIndex < (stripItems.length - 1)}
          onClick={() => shift('right')}
          {...{ gridColumnWidth }}
        />
        <Canvas>
          {stripItems?.map((item, i) => (
            <StyledGridItem 
              {...item} 
              _type="gridItem"
              key={i} 
              index={i}
              columns={item.layout?.layout?.columns ?? 6} 
              mobileColumns={item.layout?.layout?.mobileColumns ?? 5}
              {...{ gridColumnWidth, focusIndex }}
            />
          ))}
          {children}
          {!children && stripItems.map((item, i) => (
            <Placeholder key={i + 9999} >
              <StyledGridItem 
                {...item} 
                _type="gridItem"
                key={i} 
                columns={item.layout?.layout?.columns ?? 6} 
                mobileColumns={item.layout?.layout?.mobileColumns ?? 5}
                {...{ gridColumnWidth }}
                placeholder
              />
            </Placeholder>
          ))}
        </Canvas>
      </Wrapper>
    </GridItemWrapper>
  )
}



/* The trickiest part of this whole mess is getting the canvas to be the correct height.
  The items are absolute positioned, so the canvas "wants" to have a height of 0.
  Programmatically setting the height is a fucking mess.
  So we're just jamming in some 1px wide placeholders; the tallest item will do the work.
*/
const Placeholder = styled.div`
  overflow-x: hidden;
  display: inline-block;
  width: 1px;
  opacity: 0;
`


const Canvas = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`


const StyledGridItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
  placeholder?: boolean,
  focusIndex?: number,
  index: number,
}>`
  width: ${props => (props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)}px;
  @media only screen and (min-width: 744px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 20)}px;
  }
  > * {
    margin: 0 auto;
  }

  ${props => !props.placeholder && `
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    left: calc(50% - ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) / 2}px);

    transition: transform 0.45s ease-out;
    transform-origin: center center;
    
    /*
    half a screen away:                 -50vw
    width of item:                      ((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 30)) * (Math.abs(props.focusIndex - props.index)
    one deviation from index:           * (Math.abs(props.focusIndex - props.index))
    halved to bring to edge of screen:  / 2 
    */
    
    transform: translateX(
      ${props.focusIndex === props.index ? 0
        : props.index < props.focusIndex ? `calc(-50vw - ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
        : `calc(50vw + ${((props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
      }
    ); /*  scale(${props.focusIndex === props.index ? 1 : 0.8}) */
    
    @media only screen and (min-width: 744px) {
      left: calc(50% - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 20)) / 2}px);
      transform: translateX(
        ${props.focusIndex === props.index ? 0
          : props.index < props.focusIndex ? `calc(-50vw - ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
          : `calc(50vw + ${((props.columns * props.gridColumnWidth) + ((props.columns - 1) * 20)) * (Math.abs(props.focusIndex - props.index)) / 2}px)`
        }
      );
    }
    

  `}
  
  /* 
    override "fade up" animation, it messes up the scroller 
    override opacity, it doesn't trigger until more than inside margin
  */
  img, video {
    transform: none !important;
    opacity: 1 !important;
  }
      
`


const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  transition: background-color 0.15s ease-in-out;
]`



export default GridItemFocusStrip