import React from "react"
import styled from 'styled-components'
import { LinkMask, Picture, } from '@components/atoms'
import { H3, Tag } from '@components/atoms/BlockRenderer'
import { FridayFivesFeatureProps } from '@types'
import { palette } from '@theme'


const FridayFeature = ({ description, featureDate, featureImage, link, ...props }: FridayFivesFeatureProps) => {
  const formatSanityDate = (sanityDate: string): string => {
    // 2021-10-27 to 10.27.21
    return `${sanityDate.substr(5, 2)}.${sanityDate.substr(8, 2)}.${sanityDate.substr(2, 2)}`
  }
  return (
    <Wrapper { ...props }>
      {link && <LinkMask {...{ link }} />}
      <Picture src={featureImage.asset.url} /> 
      <Description>{description}</Description>
      <Date>{formatSanityDate(featureDate)}</Date>
    </Wrapper>  
  )
}


const Wrapper = styled.div`
  position: relative;
  grid-column-end: span 5;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 4;
  }
`


const Description = styled(props => <H3 {...props} />)`
  margin-top: 1em;
`


const Date = styled(props => <Tag {...props} />)`
  color: ${palette.teak};
`


export default FridayFeature