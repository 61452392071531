import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Link, } from '@components/atoms'
import { palette  } from '@theme'
import { useLocation } from '@reach/router'
import { StoreContext, useWindowSize, useNavigationMode, useNavigationModeDispatch } from '@utils'


const Navigation = () => {
  const { pathname } = useLocation()
  const { navVisible, mobileNavVisible } = useNavigationMode()
  const dispatch = useNavigationModeDispatch()
  const windowSize = useWindowSize()
  const [footerHeight, setFooterHeight] = React.useState(430)
  React.useEffect(() => setFooterHeight(typeof document !== 'undefined' ? document.getElementById('footer')?.clientHeight || 430 : 430), [windowSize])
  
  const { sanitySiteSettings: { navigationLinks } } = useStaticQuery(graphql`
    query NavigationQuery {
      sanitySiteSettings {
        navigationLinks {
          label
          url
        }
      }
    }
  `)

  const hiddenNavigation = false
  
  const { checkout, cartVisible, setCartVisible } = React.useContext(StoreContext)
  const quantity = checkout.lineItems.reduce((a, b) => a + b.quantity, 0)
  const toggleCart = () => setCartVisible(!cartVisible)
  
  const hideMobileNavVisible = () => dispatch({type: 'setMobileNavigationMode', payload: { mobileNavVisible: false }})
  
  const gradientMode = ['local-works'].includes(pathname.replace(/\//g, ''))

  return (
    <Wrapper navVisible={!hiddenNavigation && navVisible} {...{ footerHeight, mobileNavVisible, gradientMode }} >
      <ul>
        {quantity > 0 && (
          <NavItem onClick={() => toggleCart()} >
            <img src="/images/cart.svg" /> ({quantity})
          </NavItem>
        )}
        {navigationLinks.map((link: NavigationLink) => (
          <NavItem onClick={() => hideMobileNavVisible()} key={link.url} >
            <NavLink to={link.url} $active={pathname === link.url} $gradientMode={gradientMode} >{link.label}</NavLink>
          </NavItem>
        ))}
      </ul>
    </Wrapper>
  )
}


interface NavigationLink {
  label: string
  url: string
}


const NavLink = styled(props => <Link {...props} />)<{
  $active: boolean,
  $gradientMode: boolean
}>`
  transition: color 0.3s ease-in-out;
  color: ${props => props.$active ? palette.chalice : palette.white};

  text-decoration: none;
  @media only screen and (min-width: 835px) {
    color: ${props => props.$active && !props.$gradientMode ? '#fff' : props.$active && props.$gradientMode ? '#000': '#888'};
    &:hover {
      color: ${props => props.$active && !props.$gradientMode ? '#fff' : props.$active && props.$gradientMode ? '#000': '#888'};
    }
    position: relative;
    transition: transform 0.15s ease-in-out;
    &:before {
      position: absolute;
      height: 95%;
      bottom: 0;
      width:  20px;
      left: -22px;
      content: ' ';
      background: url('/images/nav_arrow.svg');
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;
      transition: opacity 0.15s ease-in-out;
      opacity: 0;
    }
  }
`

const NavItem = styled.li`
  display: block;
  color: #fff;
  cursor: pointer;
  @media only screen and (min-width: 835px) {
    transition: transform 0.15s ease-in-out;
    &:hover {
      transform: translateX(20px);
      ${NavLink} {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  img {
    vertical-align: bottom;
  }
`


const Wrapper = styled.nav<{
  navVisible: boolean,
  mobileNavVisible: boolean,
  footerHeight: number,
  gradientMode: boolean
}>`
  @media only screen and (max-width: 834px) {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 120px 0 0 20px;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ul {
      pointer-events: all;
      transform: translateY(20px);
      transition: transform 0.3s 0s ease-in-out;
      ${props => props.mobileNavVisible && `
        transform: translateY(0px);
      `}
    }

    text-align: left;
    font-size: 28px;
    line-height: 1.6em;
    background: black;

    visibility: hidden;
    touch-events: none;
    opacity: 0;
    transition: 
      visibility 0.01s 0.35s, 
      opacity 0.3s 0s ease-in-out;
    will-change: opacity, transform;
    ${props => props.mobileNavVisible && `
      visibility: visible;
      touch-events: all;
      opacity: 1;
      transition: 
        visibility 0.01s, 
        opacity 0.15s 0.1s ease-in-out, 
        transform 0.3s 0.1s ease-in-out;
    `}
  }

  @media only screen and (min-width: 743px) and (max-width: 834px) {
    padding: 100px 0 0 20px;
    ul {
      padding-left: 120px;
    }
  }
  
  @media only screen and (min-width: 835px) {
    position: fixed;
    z-index: 1000;
    bottom: 0px;
    left: 0px;
    padding: 30px 40px;
    
    font-size: 24px;
    line-height: 1.6em;
    
    ${props => props.gradientMode
      ? `
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      `
      : `mix-blend-mode: difference;`
    }
    
    opacity: ${props => props.navVisible ? 1 : 0};
    transition: opacity 0.15s ease-in-out;
  }

  @media only screen and (min-width: 1440px) {
    padding: 2.775vw 40px;
  }
  
  @media only screen and (min-width: 1600px) {
    padding: 44px 40px;
    font-size: 26.5px;
  }
  
`

export default Navigation