import React from "react"
import styled from 'styled-components'
import { Picture, LinkMask } from '@components/atoms'
import { GridItemFX } from './'
import { ChildrenWrapper } from './GridItem'
import { GridItemWrapper } from '@components/molecules'
import { GridItemImageProps } from '@types'
import { useGridHelper, isColorDark } from '@utils'


const GridItemImage = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  description,
  gridItemTypeSwitch,
  className,
  hasFX,
  parallaxSpeed,
  screenSnap,
  ...props
}: { className?: string } & GridItemImageProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemImage ) return <></>
  const {
    gridItemImage: {
      image,
      mobileImage,
    },
  } = gridItemTypeSwitch
  
  const hoverColor = !(props.extraHomeFields?.backgroundColor) ? undefined
    : props.extraHomeFields.backgroundColor.rgb
  const hoverTextColor = !(props.extraHomeFields?.backgroundColor) ? 'white'
    : isColorDark(props.extraHomeFields.backgroundColor.rgb, .6) ? 'white' : 'black'
  
  return (
    <StyledGridItemWrapper {...{ gridHelperEnabled, fullBleed, screenSnap, className }} {...layout} >
      <LinkMask {...{link, post}} />
      <GridItemFX {...{ hasFX }} parallaxSpeed={parallaxSpeed || 1} >
        <PictureWrapper className="zoomable" {...{ description, hoverColor, hoverTextColor }} >
          <Picture 
            src={image?.asset.url}
            dimensions={image?.asset.metadata.dimensions}
            mobileSrc={mobileImage?.asset.url} 
            alt={description}
            columns={layout?.columns || 12}
          />
        </PictureWrapper>
      </GridItemFX>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </StyledGridItemWrapper>
  )
}

const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)`
  line-height: 0;
`

export const PictureWrapper = styled.div<{
  description?: string,
  hoverColor?: {
    r: number,
    g: number,
    b: number
  },
  hoverTextColor?: string,
}>`
  overflow: hidden;
  line-height: 0;
  position: relative;
  ${props => !!props.description && !!props.hoverColor && !!props.hoverTextColor && `
    &:after {
      display: flex;
      flex-direction: column;
      justify-content: center;
      content: '${props.description} ';
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba( 
        ${props.hoverColor?.r ?? 255},
        ${props.hoverColor?.g ?? 255},
        ${props.hoverColor?.b ?? 0},
      0.9);
      
      color: ${props.hoverTextColor ?? 'white'};
      transition: opacity 0.5s ease-in-out;
      opacity: 0;
  
      padding: 50px;

      font-family: 'Teodor', sans-serif;
      font-weight: 300;
      line-height: 1.1em;
      font-size: 36px;
      @media only screen and (min-width: 835px) {
        font-size: 40px;
        margin-bottom: 0.5em;
      }
      @media only screen and (min-width: 1100px) {
        font-size: 52px;
        margin-bottom: 0.5em;
      }
      @media only screen and (min-width: 1600px) {
        font-size: 64px;
      }

    }
  `}
`


export default GridItemImage