import React from 'react'
import styled from 'styled-components'
import { StoreContext } from '@utils'



const QuantityWidget = ({ id, quantity }: { id: string, quantity: number }) => {
  const { checkout, loading, updateLineItem } = React.useContext(StoreContext)
  const adjust = (amount: number) => !loading && updateLineItem && updateLineItem(checkout.id!, id, quantity + amount)
  return (
    <Wrapper>
      <AdjustQuantity $loading={loading} onClick={() => adjust(-1)} >-</AdjustQuantity>
      <Quantity $loading={loading} >{quantity}</Quantity>
      <AdjustQuantity $loading={loading} onClick={() => adjust(1)} >+</AdjustQuantity>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  margin-top: 0.75em;
  border: 1px solid white;
  display: inline-flex;
  flex-direction: row;
  align-self: flex-start;
`



const AdjustQuantity = styled.div<{ $loading: boolean }>`
  padding: 5px 10px;
  cursor: pointer;
  background: black;
  color: white;
  &:hover {
    background: ${props => props.$loading ? 'black' : 'white'};
    color: ${props => props.$loading ? 'white' : 'black'};
  }
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out;
`



const Quantity = styled.div<{ $loading: boolean }>`
  padding: 5px;
  border-left: 1px solid right;
  border-right: 1px solid right;
  opacity: ${props => props.$loading ? .5 : 1};
  transition: opacity 0.15s ease-in-out;
  min-width: 35px;
  text-align: center;
`



export default QuantityWidget