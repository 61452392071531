import styled from 'styled-components'
import { palette } from '@theme'


export default styled.div<{ disabled: boolean, invert?: boolean }>`
  input[type=button], input[type=submit] {
    -webkit-appearance: none;
    border-radius: 0px;
    background: ${props => props.invert ? palette.white : palette.thunder};
    color: ${props => props.invert ? palette.thunder : palette.white};
    display: inline-block;
    padding: 10px 30px;
    margin-top: 2em !important;
  
    font-family: 'Founders', sans-serif;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 11px;
    @media only screen and (min-width: 835px) {
      font-size: 14px;
    }
    @media only screen and (min-width: 1600px) {
      font-size: 15px;
    }
    opacity: ${props => props.disabled ? 0.25 : 1};
    transition: opacity 0.15s ease-in-out;
    cursor: ${props => props.disabled ? 'not-allowed' : 'auto'};
  }
`
