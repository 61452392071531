import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { BlockRenderer, Grid, Margin, } from '@components/atoms'
import { H3 } from '@components/atoms/BlockRenderer'
import { GridItem, Hero } from '@components/molecules'
import { Filters, TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { palette } from '@theme'
import { getCdnPath } from '@utils'
import { uniq } from 'lodash'
import { Helmet } from 'react-helmet'


const IndexPage = ({
  data: { 
    sanityPage: {
      heroType, 
      heroHeight,
      heroImageConditional, 
      heroVideoConditional,
      heroFX,
      _rawIntroduction,
      gridItems,
      gridSnap,
      inverted,
      shareImage,
    },
  }
}: PageProps) => {
  const meta = !shareImage ? [] : [
    { property: "og:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) },
    { name: "twitter:image", content:  getCdnPath(`${shareImage.asset.url}?w=640&fm=jpg`) },
  ]

  const sectors = uniq(gridItems.filter(i => !!i.extraHomeFields?.sectors).flatMap(i => i.extraHomeFields.sectors).map(i => i.sector))
  const [activeSector, setActiveSectorState] = React.useState<string>()
  const filteredGridItems = gridItems.filter(i => !activeSector || i.extraHomeFields?.sectors.filter(({ sector }: { sector: string }) => sector === activeSector).length > 0)
  
  const [shifting, setShifting] = React.useState(false)
  const setActiveSector = (sector?: string) => {
    setShifting(true)
    setTimeout(() => {
      setActiveSectorState(sector)
      setTimeout(() => {
        setShifting(false)
      }, 10)
    }, 550)
  }
  
  return (
    <>
      <Helmet {...{meta}} />
      <Hero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroFX }} />
      <StyledMargin>
        <Grid {...{ gridSnap }} >
          <PageIntroduction>
            <BlockRenderer>{_rawIntroduction}</BlockRenderer>
          </PageIntroduction>
        </Grid>
      </StyledMargin>
      <Margin>
        <Grid>
          <Filters {...{ sectors, activeSector, setActiveSector }} />
        </Grid>
      </Margin>
      <RelativeMargin>
        <FilterTransitionMask {...{ shifting }} />
        <Grid>
          {filteredGridItems.map((item, i) => (
            <StyledGridItem 
              key={`grid_item_${item._key}`} 
              index={i}
              {...item}
              filtersActive={activeSector}
            >
              {!item.post && <PostTitle>{item.description}</PostTitle>}
              {item.post && (<>
                <PostTitle className="linkHighlight" >{item.post.title}</PostTitle>
                <Subhead>View Case Study</Subhead>
              </>)}
            </StyledGridItem>
          ))}
        </Grid>
      </RelativeMargin>

      <TransitionMask {...{ inverted }} />
    </>
  )
}


const StyledMargin = styled(props => <Margin {...props} />)`
  padding: 28vw 0 10vw 0;
  @media only screen and (min-width: 744px) {
    padding: 20vw 0 6vw 0;
  }
  @media only screen and (min-width: 835px) {
    padding: 13vw 0 2vw 0;
  }
`


const FilterTransitionMask = styled.div<{ shifting: boolean }>`
  position: absolute;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  opacity: ${props => props.shifting ? 1 : 0};
  will-change: opacity;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
`


const RelativeMargin = styled(props => <Margin {...props} />)`
  position: relative;
  overflow-x: hidden;
`


const StyledGridItem = styled(props => <GridItem {...props} />)<{
  filtersActive: boolean,
  index: number,
}>`
  ${props => props.filtersActive && `
    grid-column-start: 1 !important;
    grid-column-end: span 6 !important;
    @media only screen and (min-width: 744px) {
      grid-column-start: ${props.index % 2 ? 8 : 3} !important;
      grid-column-end: span 5 !important;
    }
  `}
`


export const PostTitle = styled(props => <H3 {...props} />)`
  margin: 0.5em 0 0em 0;
  text-decoration: underline;
  text-decoration-color: rgba(0, 0, 0, 0);
  transition: text-decoration-color 0.15s ease-in-out;
  text-underline-offset: 5px;
  text-decoration-thickness: 1px;
`


export const Subhead = styled(props => <H3 {...props} />)`
  color: ${palette.chalice};
`


const PageIntroduction = styled.header`
  grid-column: 1 / span 6;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 8;
  }
`


export const query = graphql`
  query HomePageQuery {
    sanityPage(slug: {current: {eq: "home"}}) {
      ...pageFields
    }
    allSanitySector {
      nodes {
        sector
      }
    }
  }
`

export default IndexPage
