import React from "react"
import styled from 'styled-components'
import { Link } from '@components/atoms'
import { SimplePostProps } from '../../types/GridItemProps'


interface LinkMaskProps {
  post?: SimplePostProps
  link?: string
  to?: string
}


const LinkMask = ({ post, link, to, }: LinkMaskProps) => {
  if(link) {
    return <AnchorWrapper href={link} target="_blank" />
  }
  if(post) {
    return <LinkWrapper to={`/${post.slug.current}`} />
  }
  if(to) {
    return <LinkWrapper to={`/${to}`} />
  }
  return <></>
}


export const LinkMaskStyle = `
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  &:hover ~ div .linkHighlight,
  &:hover ~ .linkHighlight {
    color: black;
    text-decoration-color: rgba(0, 0, 0, 0.7);
  }
  
  &:hover ~ .zoomable video,
  &:hover ~ .zoomable img {
    transform: scale(1.05);
  }
  
  &:hover ~ .zoomable:after {
    opacity: 1;
  }
`

const AnchorWrapper = styled.a`
  ${LinkMaskStyle}
`


const LinkWrapper = styled(props => <Link {...props} />)`
  ${LinkMaskStyle}
`


export default LinkMask