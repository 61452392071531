import React from "react"
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from '@utils'


interface XGridProps {
  children: React.ReactNode
  className?: string
}


const XGrid = ({ children, className, }: XGridProps): React.ReactElement => {
  const { width } = useWindowSize()
  const [colWidth, setColWidth] = React.useState(75)
  React.useEffect(() => {
    const fractionalColumnWidth = (width < 744) ? .16666 : .08333
    setColWidth(Math.floor(width * fractionalColumnWidth))
  }, [width])

  const [scrollerHeight, setScrollerHeight] = React.useState(2400)
  const ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    setScrollerHeight(ref.current?.scrollWidth || 2400)
  }, [colWidth])

  useScrollPosition(
    ({ currPos }) => ref.current?.scrollTo({behavior: 'auto', left: currPos.y * -1, top: 0}),
    [], // dependencies
    // @ts-ignore this hook is weird about typescript 
    undefined, // position of element 
    false, // use window instead of body.getBoundingClientRect
    1, // performance debounce
  )
  
  return (
    <YWrapper {...{ scrollerHeight }} >
      <XWrapper {...{ className, ref, colWidth }}>
        {children}
      </XWrapper>
    </YWrapper>
  )
}


const YWrapper = styled.div<{ scrollerHeight: number }>`
  width: 100%;
  height: ${props => props.scrollerHeight}px;
`

// 1200 40 75 20

const XWrapper = styled.div<{ colWidth: number }>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-auto-columns: ${props => props.colWidth}px;
  grid-template-rows: 70vh 1fr;
  grid-auto-flow: column;
  grid-row-gap: 0;
  grid-column-gap: 0;
  margin: auto;

  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display:none;
    width: 0;
    height: 0;
  }
`

export default XGrid