import React from "react"
import styled from 'styled-components'
import { ColorShifter } from '@components/atoms'
import {
  GridItemCaption,
  GridItemImage,
  GridItemImageFlipper,
  GridItemVideo,
  GridItemFocusStrip,
  GridItemScrollStrip,
  GridItemAutoscrollStrip,
  GridItemQuotation,
  GridItemLottie,
  GridItemVector,
  GridItemKenBurns,
  Job,
  PressRelease,
  GridRule,
  GridSwitcher,
} from '@components/molecules'
import { 
  GridItemProps, 
  GridItemCaptionProps, 
  GridItemVideoProps, 
  GridItemImageProps, 
  GridItemImageFlipperProps,
  GridItemStripProps, 
  GridItemQuotationProps, 
  GridItemLottieProps,
  JobProps, 
  PressReleaseProps,
  GridRuleProps,
  GridSwitcherProps,
  ColorShifterProps,
  GridItemVectorProps,
} from '@types'


const GridItem = (
  props: GridItemProps | GridItemStripProps | GridItemQuotationProps | JobProps | PressReleaseProps | GridRuleProps | ColorShifterProps | GridSwitcherProps
): React.ReactElement => {
  const defaultLayout = {
    fullBleed: false,
    layout: {
      columns: 12,
      mobileColumns: 6,
      rows: 1,
      vAlign: 'top',
      columnStart: 1,
      mobileColumnStart: 1,
      margin: undefined,
    }
  }
  if( props._type === 'colorShifter') {
    return <ColorShifter {...props as ColorShifterProps} gridSnap={props.gridSnap} />
  }
  if( props._type === 'gridRule') {
    return <GridRule {...props as GridRuleProps} />
  }
  if( props._type === 'gridItemStrip' && props.stripStyle == 'focus' ) {
    return <GridItemFocusStrip {...props as GridItemStripProps} />
  }
  if( props._type === 'gridItemStrip' && props.stripStyle == 'scroll' ) {
    return <GridItemScrollStrip {...props as GridItemStripProps} />
  }
  if( props._type === 'gridItemStrip' && props.stripStyle == 'autoscroll' ) {
    return <GridItemAutoscrollStrip {...props as GridItemStripProps} />
  }
  if( props._type === 'gridItemStrip' && props.stripStyle == 'kenBurns' ) {
    return <GridItemKenBurns {...props as GridItemStripProps} />
  }
  if( props._type === 'job') {
    return <Job {...props as JobProps} />
  }
  if( props._type === 'pressRelease') {
    return <PressRelease {...props as PressReleaseProps} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'quotation' ) {
    return <GridItemQuotation {...props as GridItemQuotationProps} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'gridSwitcher' ) {
    return <GridSwitcher {...props as GridSwitcherProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'caption' ) {
    return <GridItemCaption {...props as GridItemCaptionProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'image' ) {
    return <GridItemImage {...props as GridItemImageProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'imageFlipper' ) {
    return <GridItemImageFlipper {...props as GridItemImageFlipperProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'video' ) {
    return <GridItemVideo {...props as GridItemVideoProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'lottieAnimation' ) {
    return <GridItemLottie {...props as GridItemLottieProps} layout={props.layout || defaultLayout} />
  }
  if( props._type === 'gridItem' && props.gridItemTypeSwitch?.gridItemType === 'vector' ) {
    return <GridItemVector {...props as GridItemVectorProps} layout={props.layout || defaultLayout} />
  }
  return <></>
}


export const ChildrenWrapper = styled.div`
`


export default GridItem