import React from 'react'
import styled from 'styled-components'
import { TagText } from '@components/atoms/BlockRenderer'
import { useDisplayMode, isColorDark } from '@utils'


const ScrollToForm = ({children}: {children: React.ReactNode}) => {
  const { backgroundColor } = useDisplayMode()
  const dark = isColorDark(backgroundColor)
  const smooveScroll = () => 
    typeof window !== 'undefined'
    && typeof document !== 'undefined'
    && window.scrollTo({ behavior: 'smooth', left: 0, top: document.getElementById('contactForm')!.offsetTop - (window.innerWidth < 600 ? 100 : 250) })

  const visible = true
  // todo: fix this
  {/* const [visible, setVisible] = React.useState(false)
  useScrollPosition(
    ({ currPos }) => {
      setVisible( currPos.y < document.getElementById('contactForm')!.offsetTop - (window.innerWidth < 600 ? 100 : 250) )
    }, // effect callback
    [], // dependencies
    undefined, // position of element
    false, // use window instead of body.getBoundingClientRect
    15, // performance debounce
  ) */}
  
  return (
    <ScrollToFormWrapper onClick={() => smooveScroll()} {...{ dark, visible }} >{children}</ScrollToFormWrapper>    
  )  
}
  

export const ScrollToFormWrapper = styled(props => <TagText {...props} />)<{ dark: boolean }>`
  position: fixed;
  z-index: 50;
  top: 24px;
  right: -1px;
  padding: 10px 15px;
  @media only screen and (min-width: 325px) {
    right: 20px;
    padding: 10px 25px;
  }
  @media only screen and (min-width: 744px) {
    top: 5vw;
    right: 30px;
    padding: 10px 40px;
  }
  @media only screen and (min-width: 1152px) {
    right: 40px;
  }
  @media only screen and (min-width: 1440px) {
    right: 2.775vw;
  }
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  opacity: ${props => props.visible ? 1 : 0};
  filter: invert(${props => props.dark ? 1 : 0});
  transition: filter 0.5s ease-in-out, opacity 0.15s ease-in-out;
  pointer-events: ${props => props.visible ? 'all' : 'none'};
`


export default ScrollToForm