import styled from 'styled-components'
import { palette } from '@theme'


export default styled.input<{
  twoCol?: boolean,
  filled: boolean,
  invert?: boolean
}>`
  grid-column-end: span 1;
  -webkit-appearance: none;
  border-radius: 0px;
  display: block;
  border-bottom: 1px solid ${props => props.filled && !props.invert? palette.thunder : palette.chalice};
  &:focus {
    border-bottom: 1px solid ${props => !props.invert ? palette.thunder : palette.chalice};
  }
  transition: border-color 0.15s ease-in-out;
  border-radius: 0;
  margin-bottom: 0.5em;
  padding: 15px 0px 0px 0px;
  @media only screen and (min-width: 375px) {
    padding: 35px 0px 0px 0px;
  }
  grid-column-end: span 1;
  @media only screen and (min-width: 744px) {
    ${props => props.twoCol && `
      grid-column-end: span 2;
    `}
  }
  color: ${props => props.invert ? 'white' : palette.thunder};;
  &::placeholder
    color: ${props => props.invert ? palette.chalice : 'black'};;
    opacity: 1;
  }
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  line-height: 2em;
  margin-bottom: 0.5em;
  font-size: 16px;
  @media only screen and (min-width: 835px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
`

