type ColorProp = { r: number, g: number, b: number }


export default (color?: ColorProp | string, threshold?: number): boolean => {
    
  const testColor = (r: number, g: number, b: number) => {
    return ( r + g + b ) / 3 < ( 256 * (threshold ?? 0.5) )
  }

  if(!color) return false

  if( typeof color === 'string' && color.substring(0, 1) === '#') {
    return testColor(
      parseInt(color.substring(1, 2), 16),
      parseInt(color.substring(3, 2), 16),
      parseInt(color.substring(5, 2), 16)
    )
  }
  
  if( typeof color === 'string' && color.substring(0, 3) === 'rgb') {
    const rgb = color.replace(/[^\d,]/g, '').split(',')
    return testColor(parseInt(rgb[0]), parseInt(rgb[1]), parseInt(rgb[2]))
  }
  
  if( typeof color === 'string') { // invalid color
    return true
  }
  
  return testColor(color.r, color.g, color.b)

}