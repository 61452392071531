import React from "react"
import styled from 'styled-components'


interface FiltersProps {
  sectors: string[]
  activeSector?: string
  setActiveSector: (t?: string) => void
}

const Filters = ({ sectors, activeSector, setActiveSector }: FiltersProps) => {
  const [filtersVisible, setFiltersVisible] = React.useState(false)
  const toggle = (t: string) => setActiveSector(t)
  const clearSectors = () => setActiveSector(undefined)
  return (
    <Wrapper>
      <Trigger onClick={() => setFiltersVisible(!filtersVisible)} >Filters {filtersVisible ? '–' : '+'}</Trigger>
      <List {...{ filtersVisible }}>
        <Tag onClick={() => clearSectors()} active={!activeSector}>All</Tag>
        {sectors.sort().map(tag => (
          <Tag
            onClick={() => toggle(tag)}
            key={tag}
            active={activeSector === tag}
          >{tag}</Tag>
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  grid-column: 1 / span 6;
  line-height: 1.8em;
  @media only screen and (min-width: 744px) {
    grid-column: 3 / span 10;
  }
  @media only screen and (min-width: 835px) {
    line-height: 1.6em;
  }
  margin-bottom: 1em;
`

const Trigger = styled.div`
  cursor: pointer;
`

const List = styled.ul<{ filtersVisible: boolean }>`
  display: ${props => props.filtersVisible ? 'block' : 'none'};
`

const Tag = styled.li<{ active: boolean }>`
  display: inline-block;
  padding-right: 10px;
  transition: color 0.15s ease-in-out;
  opacity: ${props => props.active ? 1 : 0.5};
  &:hover {
    color: 0.75;
  }
  cursor: pointer;
`

export default Filters