import React from "react"
import Lottie from "lottie-react"
import styled from 'styled-components'
import { useInViewport } from 'react-in-viewport'
import { LinkMask, BlockRenderer } from '@components/atoms'
import { GridItemWrapper } from '@components/molecules'
import { GridItemLottieProps } from '@types'
import { getCdnPath, useGridHelper } from '@utils'


const GridItemLottie = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  gridItemTypeSwitch,
  className,
  screenSnap, 
}: GridItemLottieProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemLottie ) return <></>

  const lottieRef = React.useRef<any>()

  const ref = React.useRef<HTMLDivElement>(null)
  const { inViewport } = useInViewport( ref )
  
  const {
    gridItemLottie: {
      lottieFile,
      loop,
      _rawCaption,
    },
  } = gridItemTypeSwitch
  
  React.useEffect(() => {
    if(inViewport && !loop) lottieRef.current?.play()
  }, [inViewport, loop])

  const [lottieData, setLottieData] = React.useState<any>()
    
  React.useEffect(() => {
    if(!lottieFile.asset) return
    fetch(getCdnPath(lottieFile.asset.url, 'files')).then(
      res => res.json().then(
        data => {
          setLottieData(data)
        }
      )
    )
  }, [lottieFile])
  
  const alignment = 'top'
  
  return (
    <GridItemWrapper {...{ gridHelperEnabled, fullBleed, screenSnap, className }} {...layout} >
      <LinkMask {...{link, post}} />
      <LottieWrapper {...{ alignment }} {...{ ref }} >
        {lottieData && (
          <StyledLottie 
            lottieRef={lottieRef} 
            animationData={lottieData} 
            loop={loop} 
            autoplay={loop ? true : false}
          />
        )}
        {_rawCaption && (
          <CaptionWrapper>
            <BlockRenderer>{_rawCaption}</BlockRenderer>
          </CaptionWrapper>
        )}
      </LottieWrapper>
    </GridItemWrapper>
  )
}


const LottieWrapper = styled.div<{
  alignment: 'top' | 'center' | 'bottom'
}>`
  display: flex;
  flex-direction: column;
`


const StyledLottie = styled(props => <Lottie {...props} />)`
  width: 100%;
  svg {
    max-height: 100vh;
  }
`

export const CaptionWrapper = styled.div`
  width: 90%;
  padding: 0 10%;
  box-sizing: border-box;
  margin-top: 1em;
`


export default GridItemLottie