import React from 'react'

export type DisplayModeActionType = 'setDisplayMode'

interface DisplayModeAction {
  type: DisplayModeActionType
  payload?: {
    foregroundColor?: string
    backgroundColor?: string
  }
}

type Dispatch = (action: DisplayModeAction) => void
interface State {
  foregroundColor?: string
  backgroundColor?: string
}
type DisplayModeProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  foregroundColor: '#231F20',
  backgroundColor: '#ffffff',
}
const DisplayModeContext = React.createContext<State | undefined>(initialState)
const DisplayModeDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function displayModeReducer(state: State, action: DisplayModeAction): State {
  switch (action.type) {
    case 'setDisplayMode': {
      return {
        ...state, 
        foregroundColor: action.payload?.foregroundColor || initialState.foregroundColor,
        backgroundColor: action.payload?.backgroundColor || initialState.backgroundColor,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function DisplayModeProvider({children}: DisplayModeProviderProps) {
  const [state, dispatch] = React.useReducer(displayModeReducer, initialState)
  return (
    <DisplayModeContext.Provider value={state}>
      <DisplayModeDispatchContext.Provider value={dispatch}>
        {children}
      </DisplayModeDispatchContext.Provider>
    </DisplayModeContext.Provider>
  )
}

function useDisplayMode() {
  const context = React.useContext(DisplayModeContext)
  if (context === undefined) {
    throw new Error('useDisplayMode must be used within a DisplayModeProvider')
  }
  return context
}

function useDisplayModeDispatch() {
  const context = React.useContext(DisplayModeDispatchContext)
  if (context === undefined) {
    throw new Error('useDisplayModeDispatch must be used within a DisplayModeProvider')
  }
  return context
}

export { DisplayModeProvider, useDisplayMode, useDisplayModeDispatch }