import React from "react"
import styled from 'styled-components'
import { Grid, Picture, LinkMask } from '@components/atoms'
import { H2, TagText } from '@components/atoms/BlockRenderer'
import { PressReleaseProps } from '@types'
import { useGridHelper } from '@utils'
import { palette } from '@theme'


const PressRelease = (
  {title, source, logo, link}: PressReleaseProps
): React.ReactElement => {
  const layout = {
    columns: 9,
    mobileColumns: 6,
    rows: 1,
    columnStart: 3,
    mobileColumnStart: 1,
    gridHelperEnabled: false
  }
  const { gridHelperEnabled } = useGridHelper()
  return (
    <Wrapper {...layout} {...{ gridHelperEnabled }}>
      <LinkMask {...{ link }} />
      <Copy>
        <H2>{title}</H2>
        <Source>{source}</Source>
      </Copy>
      <PictureWrapper><Picture src={logo.asset.url} /></PictureWrapper>
    </Wrapper>
  )
}


export const Wrapper = styled(props => <Grid {...props} />)<{ 
  mobileColumns?: number, 
  mobileColumnStart?: number,
  columns?: number, 
  columnStart?: number,
  rows?: number,
  gridHelperEnabled: boolean,
}>`
  position: relative;
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 6};
  height: 100%;
  display: grid;  
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 0px;
  max-width: 100%;
  width: auto;
  padding: 30px 0px;
  border-top: 1px solid rgb(38, 38, 38);

  @media only screen and (min-width: 744px) {
    padding: 70px 0px;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 12};
    ${props => props.rows && props.rows !== 1 && `grid-row: span ${props.rows};`}
  }
  
  align-self: start;
  ${props => props.gridHelperEnabled && `
    outline: 1px dashed red;
  `}
`


const Copy = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`


const Source = styled(props => <TagText {...props} />)`
  color: ${palette.teak};  
`


const PictureWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 6;
  @media only screen and (min-width: 744px) {
    grid-column-start: 7;
    grid-column-end: span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`





export default PressRelease