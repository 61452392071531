/*
  source: https://github.com/gatsbyjs/gatsby-starter-shopify
*/

import * as React from "react"
// import fetch from "isomorphic-fetch"
import Client from "shopify-buy"
import { ShopValues } from "@types"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL!,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!,
  }
  // fetch
)

const defaultValues: ShopValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  setCartVisible: () => {},
  cartVisible: false,
  addVariantToCart: undefined,
  removeLineItem: undefined,
  updateLineItem: undefined,
  client,
  checkout: {
    lineItems: [],
    id: "",
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [cartVisible, setCartVisible] = React.useState(false)

  const setCheckoutItem = (checkout: any) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage?.removeItem(localStorageKey)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = async (variantId: any, quantity: number) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: quantity,
      },
    ]

    return client.checkout
      .addLineItems(checkoutID!, lineItemsToUpdate)
      .then((res: any) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = async (checkoutID: string, lineItemID: string) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res: any) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = async (
    checkoutID: string,
    lineItemID: string,
    quantity: number
  ) => {
    setLoading(true)

    const lineItemsToUpdate = [{ id: lineItemID, quantity: quantity }]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res: any) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        setCartVisible,
        cartVisible,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
