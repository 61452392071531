import React from 'react'
import styled from 'styled-components'
import { palette } from '@theme'
import { useDisplayMode, isColorDark } from '@utils'


interface MenuButtonProps {
  active?: boolean
  className?: string
}


const MenuButton = ({ active, className, ...props }: MenuButtonProps): React.ReactElement => {
  const { backgroundColor } = useDisplayMode()
  const inverted = isColorDark(backgroundColor)
  return (
    <Wrapper {...{ className, active, inverted }} {...props} >
      <Line {...{ inverted, active }} />
      <Line {...{ inverted, active }} />
      <Line {...{ inverted, active }} />
    </Wrapper>
  )
}


const Wrapper = styled.div<{
  inverted: boolean,
  active?: boolean
}>`
  position: relative;
  width: 55px;
  height: 55px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${props => props.active || props.inverted ? 'rgb(50, 50, 50)' : 'rgb(240, 240, 240)'};
  background: ${props => props.active || props.inverted ? 'rgb(0, 0, 0)' : 'white'};
  border-radius: 9999px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
`


const Line = styled.div<{
  inverted: boolean,
  active?: boolean
}>`
  position: absolute;
  left: 13px;
  height: 1px;
  background-color: ${props => props.active || props.inverted ? 'white' : palette.thunder};
  width: 28px;
  border-radius: 0 1px 1px 0;
  transform: rotate3d(0, 0, 1, 0deg);
  transition: transform 0.35s ease-in-out,
              background-color 0.35s ease-in-out;

  &:nth-of-type(1) {
    top: 17px;
    transform-origin: top left;
    transform: ${props => props.active
      ? `translate3d(4px, -1px, 0) rotateZ(45deg)`
      : `translateY(0) rotateZ(0deg)`
    };
  }
  &:nth-of-type(2) {
    top: 26px;
    transform-origin: right center;
    transform: ${props => props.active
      ? `scale(0)`
      : `scale(1)`
    };
  }

  &:nth-of-type(3) {
    top: 35px;
    transform-origin: top left;
    transform: ${props => props.active
      ? `translate3d(3px, 1px, 0) rotateZ(-45deg)`
      : `translateY(0) rotateZ(0deg)`
    };
  }
`


export default MenuButton