import React from "react"
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Footer, Header } from '@components/organisms'
import { Cart } from '@components/cart'
import { SiteSettings } from '@types'
import { useDisplayMode } from '@utils'
import { palette } from '@theme'            
import { useLocation } from '@reach/router'

import "../assets/stylesheets/reset.min.css"
import "../assets/stylesheets/main.scss"


const Layout = ({ className, children }: LayoutProps): React.ReactNode => {
  const { foregroundColor, backgroundColor } = useDisplayMode()
  const [loaded, setLoaded] = React.useState(false)
  React.useEffect(() => {
    setTimeout(() => setLoaded(true), 5)
  }, [])
  
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      sanitySiteSettings {
        ...siteSettings
      }
    }
  `)

  const { 
    sanitySiteSettings: { 
      title, description, keywords, shortcutIcon, touchIcon, shareImage 
    }
  } = data
  const meta = [
    { property: "og:type", content: "website" },
    { property: "og:url", content: `${process.env.GATSBY_SITE_BASE_URL}` },
    { charSet: 'UTF-8' },
    { name: "viewport", content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0" },
    { httpEquiv: "X-UA-Compatible", content: "ie=edge" },
    { name: "apple-mobile-web-app-capable", content: "yes" },

    { property: "og:title", content: title },
    { name: "twitter:title", content: title },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "theworkingassembly" },
    
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },

    { name: "keywords", content: keywords },
    
    { property: "og:image", content: `${shareImage?.asset.url}?w=640&h=640&fm=jpg&q=75` },
    { name: "twitter:image", content: shareImage?.asset.url },
  ]

  const { pathname } = useLocation()
  const hiddenFooter = ['labs'].includes(pathname.replace(/[^a-z\-]/g, ""))

  return (
    <>
      <Helmet {...{ meta, title }} >
        <link rel="preload" as="font" href="/fonts/teodor/Teodor-Light.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/founders-grotesk/founders-grotesk-web-light.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/founders-grotesk/founders-grotesk-web-regular.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" as="font" href="/fonts/founders-grotesk/founders-grotesk-web-medium.woff2" type="font/woff2" crossOrigin="anonymous" />
        <link rel="apple-touch-icon" href={touchIcon?.asset.url} />
        <link rel="icon" type="image/png" href={shortcutIcon?.asset.url} sizes="32x32" />
        <link rel="icon" type="image/svg+xml" href="/images/favicon-vector-circle.svg" />
      </Helmet>
      <Top id="top" />
      <Header />
      <Cart />
      <Wrapper className={loaded ? 'loaded' : 'loading'} {...{ className, foregroundColor, backgroundColor, hiddenFooter }}>
        {children}
      </Wrapper>
      <Footer {...{ hiddenFooter }} />
    </>
  )
}



const Wrapper = styled.div<{ foregroundColor?: string, backgroundColor?: string, hiddenFooter: boolean }>`
  position: relative;
  z-index: 100;
  min-height: 50vh;
  color: ${props => props.foregroundColor || palette.thunder};
  background-color: ${props => props.backgroundColor || palette.white};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  padding-bottom: ${props => props.hiddenFooter ? 0 : '110px'};
  @media only screen and (min-width: 744px) {
    padding-bottom: ${props => props.hiddenFooter ? 0 : '160px'};
  }
`



const Top = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
`



interface LayoutProps extends SiteSettings {
  subtitle?: string
  preview?: boolean
  hasHero?: boolean
  className?: string
  children: React.ReactNode
}


export default Layout
