import React, { useState } from "react"
import styled from 'styled-components'
import Lottie from "lottie-react"
import { MenuButton, Link } from '@components/atoms'
import { Navigation } from '@components/organisms'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useDisplayMode, isColorDark, useNavigationMode, useNavigationModeDispatch } from '@utils'
import { useLocation } from '@reach/router'
import TwaLogo from '../../assets/images/twa-logo-3.json'


const Header = (): React.ReactElement => {
  const [logoIn, setLogoIn] = useState(true)
  const { backgroundColor } = useDisplayMode()
  const inverted = isColorDark(backgroundColor)
  const { mobileNavVisible, navVisible } = useNavigationMode()
  const dispatch = useNavigationModeDispatch()

  const logo = React.useRef<any>()
    
  const setNavVisible = (navVisible: boolean) => dispatch({type: 'setNavigationMode', payload: { navVisible }})
  const setMobileNavVisible = (mobileNavVisible: boolean) => dispatch({type: 'setMobileNavigationMode', payload: { mobileNavVisible }})

  useScrollPosition(
    ({ currPos }) => {
      if( window.innerWidth >= 834 ) {
        const aboveFooter = currPos.y < ( document.body.clientHeight - (document.getElementById('footer')?.offsetHeight || 100) - window.innerHeight )
        if( aboveFooter !== navVisible) setNavVisible( aboveFooter )
      }
      if( !logoIn && currPos.y > 10 ) {
        setLogoIn(true) // finish animation on move
        logo.current?.playSegments([60, 100], false)
      }
    }, // effect callback
    [logoIn, navVisible], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    15, // performance debounce
  )
  
  React.useEffect(() => { // play on load
    logoIn && logo.current?.playSegments([22,60], true)
    setLogoIn(false)
  }, [logo])
  
  const replay = () => {
    logoIn && logo.current?.playSegments([22,60], false)
    setLogoIn(false)
  }
  const replayEnd = () => {
    !logoIn && logo.current?.playSegments([60,100], false)
    setLogoIn(true)
  }
  
  const { pathname } = useLocation()
  const hiddenNavButton = ['labs', 'localworks'].includes(pathname.replace(/[^a-z]/g, ""))

  return (
    <>
      <LogoFixedSpace $inverted={inverted || (typeof window !== 'undefined' && mobileNavVisible)} to="/" >
        <StyledLottie 
          animationData={TwaLogo} 
          loop={false} 
          autoplay={false} 
          lottieRef={logo} 
          onMouseEnter={() => replay()} 
          onMouseLeave={() => replayEnd()} 
        />
      </LogoFixedSpace>

      <Navigation/>

      <MenuButtonWrapper onClick={() => setMobileNavVisible(!mobileNavVisible)} {...{ hiddenNavButton }}>
        <MenuButton active={mobileNavVisible} />
      </MenuButtonWrapper>

    </>
  )
}



const MenuButtonWrapper = styled.div<{ hiddenNavButton: boolean }>`
  display: ${props => props.hiddenNavButton ? 'none' : 'block'};
  position: fixed;
  z-index: 1001;
  top: 23px;
  right: 22px;
  @media only screen and (min-width: 835px) {
    display: none;
  }
`

const LogoFixedSpace = styled(props => <Link {...props} />)<{ $inverted: boolean }>`
  position: fixed;
  z-index: 1001;
  top: 20px;
  left: 20px;
  height: 60px;

  display: block;
  padding: 0;
  margin: 0;
  line-height: 0;
  transition: filter 0.15s ease-in-out;
  ${props => props.$inverted ? `filter: invert(1);` : ''}

  @media only screen and (min-width: 744px) {
    top: 30px;
    left: 30px;
  }
  
  @media only screen and (min-width: 1152px) {
    top: 40px;
    left: 40px;
  }
  
  @media only screen and (min-width: 1440px) {
    top: 2.775vw;
    left: 2.775vw;
  }
`


const StyledLottie = styled(props => <Lottie {...props} />)`
  width: 166px;
  height: 60px;
  @media only screen and (min-width: 744px) {
    width: 250px;
    height: 90px;
  }
`



export default Header