import React from "react"
import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { palette } from '@theme'


const BlockSerializer = ({children, node}: { children: any, node: any }): JSX.Element => {
  if(!children) return <></>
  switch(node.style) {
    case 'h0' : // Headline
      return <H0>{children}</H0>
    case 'h1' : // Header
      return <H1>{children}</H1>
    case 'h2' : // Subheader & Quotes
      return <H2>{children}</H2>
    case 'h3' : // Title & Navigation
      return <H3>{children}</H3>
    case 'caption': // Caption & Subparagraph
      return <Psmall>{children}</Psmall>
    case 'tag': // Tag & Eyebrow
      return <Tag>{children}</Tag>

    case 'shopH1': // Shop
      return <ShopH1>{children}</ShopH1>
    case 'shopH2': // Shop
      return <ShopH2>{children}</ShopH2>
    case 'shopH3': // Shop
      return <ShopH3>{children}</ShopH3>
    case 'shopH4': // Shop
      return <ShopH4>{children}</ShopH4>

    default: // Paragraph
      return <Paragraph>{children}</Paragraph>
  }
}

const BlockRenderer = ({children}: { children: any }) => {
  return (
    <StyledBlockContent
      blocks={children}
      serializers={{
        types: {
          block: (props: any) => (<BlockSerializer {...props} />),
        },
        marks: {
          gold: (props: any) => (<GoldText {...props} />),
          grey: (props: any) => (<GreyText {...props} />),
          darkgrey: (props: any) => (<DarkGreyText {...props} />),
        },
      }}
    />
  )
}



const StyledBlockContent = styled(props => <BlockContent {...props} />)`
  > p {
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 25px;
  }
  li {
    list-style-type: disc;
  }
`

const GoldText = styled.span`
  color: ${palette.teak};
`

const GreyText = styled.span`
  color: #d9d9d9;
`

const DarkGreyText = styled.span`
  color: #ACA8A9;
`


export const H0 = styled.h1`
  font-family: 'Teodor', sans-serif;
  font-weight: 300;
  line-height: 1.1em;
  font-size: 36px;
  @media only screen and (min-width: 835px) {
    font-size: 60px;
    margin-bottom: 0.5em;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 64px;
  }
`

export const H1 = styled.h1`
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  font-size: 34px;
  @media only screen and (min-width: 835px) {
    margin-bottom: 0.5em;
    font-size: 48px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 50px;
  }
`

export const H2 = styled.h2`
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  font-size: 26px;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  @media only screen and (min-width: 835px) {
    font-size: 34px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 36px;
  }
`

export const H3 = styled.h3`
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-size: 22px;
  @media only screen and (min-width: 835px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 26px;
  }
`

export const Paragraph = styled.p`
  font-family: 'Founders', sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-size: 16px;
  @media only screen and (min-width: 835px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
`

export const Psmall = styled.p<{ center?: boolean }>`
  font-family: 'Founders', sans-serif;
  font-weight: 400;
  line-height: 1.2em;
  margin-bottom: 0.5em;
  font-size: 12px;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 16px;
  }
`


export const TagStyle = `
  display: inline-block;
  font-family: 'Founders', sans-serif;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-size: 11px;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 16px;
  }
`

export const Tag = styled.li`
  ${TagStyle}
`

export const TagText = styled.p`
  ${TagStyle}
`


export const ShopH1 = styled.h1`
  font-family: 'Founders', sans-serif;
  font-weight: 700;
  line-height: 1.1em;
  text-transform: uppercase;
  font-size: 36px;
  @media only screen and (min-width: 835px) {
    font-size: 60px;
    margin-bottom: 0em;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 80px;
  }
`

export const ShopH2 = styled.h2`
  font-family: 'Founders', sans-serif;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  font-size: 34px;
  @media only screen and (min-width: 835px) {
    margin-bottom: 0.25em;
    font-size: 48px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 50px;
  }
`

export const ShopH3 = styled.h3`
  font-family: 'Founders', sans-serif;
  font-weight: 700;
  line-height: 1.2em;
  text-transform: uppercase;
  font-size: 22px;
  @media only screen and (min-width: 835px) {
    margin-bottom: 1em;
    font-size: 24px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 26px;
  }
`

export const ShopH4 = styled.h4`
  font-family: 'Founders', sans-serif;
  font-weight: 700;
  line-height: 1.2em;
  text-transform: uppercase;
  font-size: 16px;
  @media only screen and (min-width: 835px) {
    margin-bottom: 0.5em;
    font-size: 18px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`



export default BlockRenderer