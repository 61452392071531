import React from "react"
import styled from 'styled-components'
{/* import { Tag } from '@components/atoms/BlockRenderer' */}


const Tags = ({tags = [], className}: TagsProps): React.ReactElement => {
  return (
    <Wrapper {...{ className }} >
      {tags.map((t, i) => (
        <Tag key={i} >
          {t.tag}{i !== tags.length -1 && ', '}
        </Tag>
      ))}
    </Wrapper>
  )
}


const Wrapper = styled.ul`
  margin-top: 0.5em;
`


const Tag = styled.li`
  display: inline-block;
  font-size: 14px;
  padding-right: 5px;
`


type TagsProps = {
  tags: Array<{tag: string}>
  className?: string
}


export default Tags