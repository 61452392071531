import React from "react"
import styled from 'styled-components'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from '@components/molecules'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useGridHelper, useWindowSize, clamp } from '@utils'
import { GridItemStripProps} from '@types'


const GridItemAutoscrollStrip = ({ 
  stripItems,
  className,
}: { stackMobile?: boolean } & GridItemStripProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const { width } = useWindowSize()

  const wrapper = React.useRef<HTMLDivElement>(null)
  const items = React.useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const [scrollerWidth, setScrollerWidth] = React.useState(2400)
  const [scrollerHeight, setScrollerHeight] = React.useState(2400)
  
  useScrollPosition(
    ({ currPos }) => setScrollPosition(currPos.y * -1), // effect callback
    [setScrollPosition], // dependencies
    // @ts-ignore this hook is weird about typescript 
    wrapper, // position of element 
    false, // use window instead of body.getBoundingClientRect
    10, // performance debounce
  )
  
  const [offsetX, setOffsetX] = React.useState(0)
  React.useEffect(() => {
    const scrollPercentage = scrollPosition / (scrollerHeight - window.innerHeight)
    const x = clamp(scrollPercentage * (scrollerWidth - window.innerWidth), 0, scrollerWidth - window.innerWidth)
    setOffsetX( x )
  }, [scrollPosition])
  
  React.useEffect(() => {
    setTimeout(() => {
      const x = Array.from(items.current?.childNodes as NodeListOf<HTMLDivElement> || []).reduce( (a, b) => a + b.clientWidth, 0 ) / 2
      setScrollerWidth(x)
      setScrollerHeight(x)
    }, 500)
  }, [items.current])

  const [colWidth, setColWidth] = React.useState(0)
  React.useEffect(() => {
    setTimeout(() => setColWidth(document.getElementById('gridColumnWidth')?.offsetWidth ?? 0), 50)
  }, [width])

  return (
    <GridItemWrapper {...{ gridHelperEnabled }} >
      <Wrapper {...{ className }} height={scrollerHeight} count={stripItems?.length || 1} ref={wrapper} >
        <Sticky>
          <Items ref={items} {...{ offsetX }} >
              {stripItems && stripItems.map((item, i) => (
                <ScrollStripGridItem 
                  {...item} 
                  _type="gridItem"
                  key={i} 
                  columns={item.layout?.layout?.columns} 
                  mobileColumns={item.layout?.layout?.mobileColumns}
                  colWidth={colWidth}
                  row={item.layout?.layout?.row}
                />
              ))}
          </Items>
        </Sticky>
      </Wrapper>
    </GridItemWrapper>
  )
}


const Wrapper = styled.div<{ count: number, height: number }>`
  position: relative;
  height: ${props => props.height}px;
  width: calc(100% + 40px);
  margin-left: -20px;
  @media only screen and (min-width: 744px) {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Items = styled.div.attrs(
  ({ offsetX }: { offsetX: number }) => ({
    style: {
      transform: `translateX(${offsetX * -1}px)`
    }
  })
)`
  min-width: 100vw;
  height: 100vh;
  will-change: transform;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(99, auto);
  grid-template-rows: repeat(2, auto);
`


export const ScrollStripGridItem = styled(props => <GridItem {...props} />)<{
  colWidth: number,
  columns?: number,
  mobileColumns?: number,
  row?: number,
}>`
  max-width: 100vmin;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.row === 2 ? 'flex-start' : 'flex-end'};
  grid-row-start: ${props => props.row || 1};
  
  min-width: ${props => props.colWidth * (props.mobileColumns || 6) + (20 * props.mobileColumns || 12) - 20}px;
  @media only screen and (min-width: 744px) {
    min-width: ${props => props.colWidth * (props.columns || 12) + (20 * props.columns || 12) - 20}px;
  }
  
  /* override "fade up" animation, it messes up the scroller */
  img, video {
    transform: none !important;
  }
  
  > * {
    width: 100%;
    ${props => props.row === 2 && `
      padding: 0 8vw;
    `}
  }

  &:first-of-type {
    grid-row: 1 / span 2;
    min-width: 100vw;
    justify-content: center;
    align-items: center;
    > div {
      @media only screen and (min-width: 1024px) {
        max-width: 70vw;
      }
    }
  }
      
`


export default GridItemAutoscrollStrip