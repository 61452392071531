import React from "react"
import styled from 'styled-components'
import { BlockRenderer } from '@components/atoms'
import { GridItemQuotationProps } from '@types'
import { GridItemWrapper } from '@components/molecules'
import { palette } from '@theme'
import { useGridHelper } from '@utils'


const GridItemQuotation = ({
  className,
  layout: {
    fullBleed,
    layout,
  },
  gridItemTypeSwitch,
}: GridItemQuotationProps & { className?: string }): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const {
    gridItemQuotation: {
      _rawQuote,
      quotee,
      quoteeTitle,
    },
  } = gridItemTypeSwitch
  return (
    <GridItemWrapper {...{ gridHelperEnabled, fullBleed, className }} {...layout} >
      <ItemCaption>
        <BlockRenderer>{_rawQuote}</BlockRenderer> 
        {quotee && <Attribution>{quotee}</Attribution>}
        {quoteeTitle && <GoldAttribution>{quoteeTitle}</GoldAttribution>}
      </ItemCaption>
    </GridItemWrapper>
  )
}


const Attribution = styled.div`
  font-family: 'Founders', sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 1em;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 1.2vw;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 15px;
  }
`

const GoldAttribution = styled(props => <Attribution {...props} />)`
  color: ${palette.teak};
  margin-top: 0;
`

const ItemCaption = styled.div`
  position: relative;
`



export default GridItemQuotation