/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import { NavigationModeProvider, DisplayModeProvider, GridHelperProvider, StoreProvider } from '@utils'


export const wrapRootElement = ({ element }) => (
  <GridHelperProvider>
    <NavigationModeProvider>
      <DisplayModeProvider>
        <StoreProvider>
          {element}
        </StoreProvider>
      </DisplayModeProvider>
    </NavigationModeProvider>
  </GridHelperProvider>
)

