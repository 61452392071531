import React from "react"
import styled from 'styled-components'


interface MarginProps {
  children?: React.ReactNode
}


const Margin = ({
  children,
  ...props
}: MarginProps): React.ReactElement => {
  return (
    <Wrapper {...props}>{children}</Wrapper>
  )
}


const Wrapper = styled.div`
  margin: 0 auto;
`


export default Margin
