import React from "react"
import styled from 'styled-components'
import { ContactForm, Input, Submit, Message } from '@components/forms'
import { Psmall } from '@components/atoms/BlockRenderer'
import { validEmail } from '@utils'


const OutOfStockForm = ({ product }: { product: string }) => {
  const [sent, setSent] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState('')
  
  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    await fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify({ 'subject': `TWA Shop pre-order: ${product}`, email, name: "", borough: "", website: "", company: "", how: "", product }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (response.status !== 200) {
        throw new Error("Bad response from server");
      }
      setMessage("Thanks for reaching out!")
      setSent(true)
    })
  }
  
  const [disabled, setDisabled] = React.useState(true)
  React.useEffect(() => {
    setDisabled(
     !validEmail(email)
    )
  }, [email])

  return (
    <StyledContactForm {...{ sent }} action="https://getform.io/f/derp" method="POST" id="contactForm" columns={3} >
      <Psmall>We’re out of stock! Enter your email below and we will let you know when we’ve got more.</Psmall>
      <Input type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} required filled={email.length > 0} invert />
      <Submit {...{ disabled }} invert>
        <input type="submit" value="Notify Me" onClick={e => submit(e)} />
      </Submit>
      {message && <Message invert>{message}</Message>}
    </StyledContactForm>
  )  
}


const StyledContactForm = styled(props => <ContactForm {...props} />)`
  display: flex;
  flex-direction: column;  
`

export default OutOfStockForm