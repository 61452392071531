import React from "react"
import styled from 'styled-components'
import { GridItem } from '@components/molecules'
import { GridItemWrapper } from '@components/molecules'
import { useScrollPercentage } from 'react-scroll-percentage'
import { useWindowSize } from '@utils'
import { GridItemStripProps} from '@types'
import { useGridHelper } from '@utils'


const GridItemKenBurns = ({ 
  stripItems,
  backgroundImage,
  className,
}: { children?: React.ReactNode } & GridItemStripProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const { width, height } = useWindowSize()

  const [ref, visibility] = useScrollPercentage({
    threshold: 0,
  })
  
  const [gridColumnWidth, setGridColumnWidth] = React.useState<number>(1)
  React.useEffect(() => {
    setTimeout(() => setGridColumnWidth(document.getElementById('gridColumnWidth')?.offsetWidth ?? 0), 50)
  }, [width])

  return (
    <StyledGridItemWrapper 
      {...{ gridHelperEnabled, className }}
      mobileColumns={6}
      mobileColumnStart={1}
      columns={10}
      columnStart={3} 
      aspectRatio={backgroundImage?.asset.metadata.dimensions.aspectRatio || 1}
    >
      <Marker ref={ref} />
      <BackgroundImage imageUrl={backgroundImage?.asset.url} {...{ visibility }} />
      <Wrapper>
        {stripItems && stripItems.map((item, i) => (
          <ChildItem 
            key={`key_${i}`}
            {...item} 
            _type="gridItem"
            columns={item.layout?.layout?.columns} 
            mobileColumns={item.layout?.layout?.mobileColumns}
            {...{ gridColumnWidth }}
          />
        ))}
      </Wrapper>
    </StyledGridItemWrapper>
  )
}


const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)<{
  aspectRatio: number,
}>`
  @media only screen and (max-width: 743px) {
    height: 80vw;
  }
  position: relative;
  &:before {
    content: ' ';
    display: block;
    padding-top: ${props => 1 / props.aspectRatio * 100}%;
  }
  overflow: hidden;
`

// 5 0 5
const BackgroundImage = styled.div<{
  imageUrl?: string,
  visibility: number,
}>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 120%;
  height: 120%;
  margin-top: -10%;
  margin-left: -10%;
  ${props => props.imageUrl && `
    background-image: url('${props.imageUrl}');
    background-position: center center;
    background-size: cover;
  `}
  will-change: filter;
`


const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
`


const ChildItem = styled(props => <GridItem {...props} />)<{ 
  gridColumnWidth: number, 
  columns: number, 
  mobileColumns: number,
}>`
  justify-content: center;
  width: ${props => (props.mobileColumns * props.gridColumnWidth) + ((props.mobileColumns - 1) * 20)}px;
  @media only screen and (min-width: 744px) {
    width: ${props => (props.columns * props.gridColumnWidth) + ((props.columns - 1) * 20)}px;
  }
`


const Marker = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div ref={ref} style={{'position': 'absolute', 'top': '0', 'width': '1px', 'height': '100%' }}/>
))



export default GridItemKenBurns