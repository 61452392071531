import React from "react"
import styled from 'styled-components'
import { BlockRenderer, Grid } from '@components/atoms'
import { H1, H3, Paragraph } from '@components/atoms/BlockRenderer'
import { JobProps } from '@types'
import { useGridHelper } from '@utils'
import { palette } from '@theme'


const Job = (
  {jobTitle, team, location, _rawJobDescription}: JobProps
): React.ReactElement => {
  const ref = React.useRef<any>()
  const layout = {
    columns: 9,
    mobileColumns: 6,
    rows: 1,
    columnStart: 3,
    mobileColumnStart: 1,
    gridHelperEnabled: false
  }
  const { gridHelperEnabled } = useGridHelper()
  const [active, setActive] = React.useState(false)
  const [actualHeight, setActualHeight] = React.useState(100)
  React.useEffect(() => {
    setActualHeight(ref.current?.scrollHeight || 100)
  }, [])
  return (
    <Wrapper {...layout} {...{ gridHelperEnabled }}>
      <JobTitle onClick={() => setActive(!active)} >{jobTitle}</JobTitle>
      <Meta onClick={() => setActive(!active)} >
        <H3>{team}</H3>
        <Paragraph>{location}</Paragraph>
      </Meta>
      <Trigger onClick={() => setActive(!active)} {...{ active }} />
      <JobDescription {...{ active, actualHeight, ref }} >
        <BlockRenderer>{_rawJobDescription}</BlockRenderer>
      </JobDescription>
    </Wrapper>
  )
}


export const Wrapper = styled(props => <Grid {...props} />)<{ 
  mobileColumns?: number, 
  mobileColumnStart?: number,
  columns?: number, 
  columnStart?: number,
  rows?: number,
  gridHelperEnabled: boolean,
}>`
  position: relative;
  grid-column-start: ${props => props.mobileColumnStart || 'auto'};
  grid-column-end: span ${props => props.mobileColumns || 6};
  height: 100%;
  display: grid;  
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-row-gap: 0px;
  margin: 0;
  max-width: 100%;
  border-top: 1px solid ${palette.teak};
  padding: 10px 0px;
  width: auto;

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-start: ${props => props.columnStart || 'auto'};
    grid-column-end: span ${props => props.columns || 12};
    ${props => props.rows && props.rows !== 1 && `grid-row: span ${props.rows};`}
  }
  
  align-self: start;
  ${props => props.gridHelperEnabled && `
    outline: 1px dashed red;
  `}
`


const JobTitle = styled(props => <H1 {...props} />)`
  grid-column-start: 0;
  grid-column-end: span 5;
  grid-row: 1 / span 1;
  @media only screen and (min-width: 744px) {
    cursor: pointer;
    grid-row: 1 / span 1;
    grid-column-end: span 6;
  }
`


const Meta = styled.div`
  grid-column-start: 0;
  grid-column-end: span 5;
  padding-top: 0.25em;
  grid-row: 2 / span 1;
  @media only screen and (min-width: 744px) {
    cursor: pointer;
    grid-row: 1 / span 1;
    grid-column-start: 7;
    grid-column-end: span 2;
  }
`


const Trigger = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  &:after {
    content: ' ';
    position: absolute;
    right: 0px;
    top: 24px;
    width: 39px;
    height: 1px;
    background: ${palette.teak};
  }
  &:before {
    content: ' ';
    position: absolute;
    top: 5px;
    right: 19px;
    width: 1px;
    height: 39px;
    background: ${palette.teak};
    transition: transform 0.5s ease-in-out;
    transform: scaleY(${props => props.active ? 0 : 1});
    transform-origin: center center;
  }
  grid-column-start: 6;
  grid-column-end: span 1;
  grid-row-end: span 2;
  @media only screen and (min-width: 744px) {
    grid-column-start: 10;
    grid-row-end: span 1;
  }
`


const JobDescription = styled.div<{ active: boolean, actualHeight: number }>`
  grid-column-start: 0;
  grid-column-end: span 6;
  max-height: ${props => props.active ? props.actualHeight : 0}px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 6;
  }
`


export default Job