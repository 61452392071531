import React from 'react'
import styled from 'styled-components'
import { MenuButton } from '@components/atoms'
import { ShopH4, TagStyle, Psmall } from '@components/atoms/BlockRenderer'
import { Item } from './'
import { StoreContext } from '@utils'


const Cart = () => {
  const { checkout, loading, cartVisible, setCartVisible } = React.useContext(StoreContext)

  const quantity = checkout.lineItems.reduce((a, b) => a + b.quantity, 0)
  const subtotal = checkout.lineItems.reduce((a, b) => a + (b.quantity * parseInt(b.variant.price)), 0)
  
  React.useEffect(() => {
    if( quantity < 1 ) setCartVisible(false)
  }, [quantity])
  
  return (
    <>
      <Wrapper {...{ cartVisible }} >
        <CartContent>
          <StyledMenuButton active={true} onClick={() => setCartVisible(false)} />
            
          <Header><ShopH4>Your cart ({quantity})</ShopH4></Header>
          
          <ItemScroller>
            {checkout.lineItems.map(lineItem => (
              <Item key={lineItem.id} {...lineItem} />
            ))}
          </ItemScroller>
  
          <Footer $loading={loading} >
            <ShopH4>Subtotal <span>${subtotal}</span></ShopH4>
            <Psmall>Shipping & taxes calculated at checkout</Psmall>
            {checkout?.webUrl && (
              <CheckoutButton href={checkout.webUrl} target="_blank" >Checkout</CheckoutButton>
            )}
          </Footer>
        </CartContent>
      </Wrapper>
      <Onionskin onClick={() => setCartVisible(false)} {...{ cartVisible }}/>
    </>
  )
}


const ItemScroller = styled.div`
  overflow-y: scroll;
`



const Wrapper = styled.div<{
  cartVisible: boolean
}>`
  position: fixed;
  z-index: 2001;
  top: 0px;
  right: 0px;
  height: 100vh;
  will-change: width;
  transition: width 0.5s ease-in-out;
  overflow: hidden;
  width: ${props => props.cartVisible ? '100vw' : 0};
  @media only screen and (min-width: 375px) {
    width: ${props => props.cartVisible ? 'calc(100vw - 95px)' : 0};
  }
  @media only screen and (min-width: 744px) {
    width: ${props => props.cartVisible ? '450px' : 0};
  }
`


const Onionskin = styled.div<{
  cartVisible: boolean
}>`
  position: fixed;
  z-index: 2000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  pointer-events: ${props => props.cartVisible ? 'all' : 'none'};
  background-color: rgba(0, 0, 0, .5);
  opacity: ${props => props.cartVisible ? 1 : 0};
  transition: opacity 0.15s ease-in-out;
`


const CartContent = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100vw;
  @media only screen and (min-width: 375px) {
    width: calc(100vw - 95px);
  }
  @media only screen and (min-width: 744px) {
    width: 450px;
  }
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`


const StyledMenuButton = styled(props => <MenuButton {...props} />)`
  position: absolute !important;
  z-index: 2002;
  top: 20px;
  right: 20px;
  background: transparent;
  border: 0;
  @media only screen and (min-width: 744px) {
    top: 1vw;
    right: 2vw;
  }
`


const Header = styled.header`
  border-bottom: 1px solid white;
  padding: 70px 0 2vw 0;
  margin: 0 40px 2vw 40px;
  @media only screen and (min-width: 744px) {
    padding: 2vw 0;
    margin: 0 3vw 2vw 3vw;
  }
`


const Footer = styled.footer<{ $loading: boolean }>`
  background: rgb(25, 25, 25);
  padding: 20px 30px;
  @media only screen and (min-width: 744px) {
    padding: 2vw 3vw;
  }
  margin-top: auto;
  span {
    opacity: ${props => props.$loading ? .5 : 1};
    transition: opacity 0.15s ease-in-out;
  }
`




const CheckoutButton = styled.a`
  ${TagStyle}
  display: block;
  width: 100%;
  background: white;
  color: black;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 12px;
  margin-top: 2em;
  &:hover {
    opacity: 0.75;
  }
  transition: opacity 0.15s ease-in-out;

`


export default Cart