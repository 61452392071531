import React from "react"
import { LinkMask, Video } from '@components/atoms'
import { ChildrenWrapper } from './GridItem'
import { GridItemWrapper } from '@components/molecules'
import { GridItemFX } from './'
import { GridItemVideoProps } from '@types'
import { useGridHelper, isColorDark } from '@utils'
import { PictureWrapper } from '@components/molecules/GridItemImage'


const GridItemVideo = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  description,
  gridItemTypeSwitch,
  className,
  hasFX,
  parallaxSpeed,
  screenSnap,
  ...props
}: GridItemVideoProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridItemVideo ) return <></>
  const {
    gridItemVideo: {
      video,
      mobileVideo,
    },
  } = gridItemTypeSwitch
  
  const hoverColor = !(props.extraHomeFields?.backgroundColor) ? undefined
    : props.extraHomeFields.backgroundColor.rgb
  const hoverTextColor = !(props.extraHomeFields?.backgroundColor) ? 'white'
    : isColorDark(props.extraHomeFields.backgroundColor.rgb, .6) ? 'white' : 'black'

  return (
    <GridItemWrapper {...{ gridHelperEnabled, fullBleed, screenSnap, className }} {...layout} >
      <LinkMask {...{link, post}} />
      <GridItemFX {...{ hasFX }} parallaxSpeed={parallaxSpeed || 1} >
        <PictureWrapper className="zoomable" {...{ description, hoverColor, hoverTextColor }} >
          {mobileVideo && (
            <Video autoPlay muted loop hideIfDesktop playsInline src={mobileVideo.asset.url} />
          )}
          <Video autoPlay muted loop hideIfMobile={!!mobileVideo} playsInline src={video?.asset.url} />
        </PictureWrapper>
      </GridItemFX>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </GridItemWrapper>
  )
}





export default GridItemVideo