import React from "react"
import styled from 'styled-components'

interface GridProps {
  gridSnap?: boolean
  children: React.ReactNode
  className?: string
  id?: string
}

const Grid = ({ children, className, id, }: GridProps): React.ReactElement => (
  <Wrapper {...{ className, id }}>
    {children}
  </Wrapper>
)

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: auto;
  width: calc(100% - 40px);

  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(12, 1fr);
    width: calc(100% - 60px);
  }
  
  @media only screen and (min-width: 1152px) {
    width: calc(100% - 80px);
  }
  
  @media only screen and (min-width: 1440px) {
    width: calc(100% - 5.55vw);
    grid-column-gap: 1.3875vw;
  }
`

export default Grid