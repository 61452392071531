import styled from 'styled-components'


export default styled.div<{ left?: boolean, active: boolean, }>`
  position: absolute;
  z-index: 100;
  ${props => props.left ? `
    left: 0px;
    transform: scaleX(-1);
  ` : `
    right: 0px;
  `}
  top: 0;
  bottom: 0;
  width: 70px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  transition: opacity 0.25s ease-in-out;
  opacity: ${props => props.active ? 1 : 0};
  cursor: ${props => props.active ? 'pointer' : 'inherit'};
  @media only screen and (min-width: 744px) {
    width: 90px;
    background-size: 75px 75px;
  }
  @media only screen and (min-width: 1024px) {
    width: 120px;
  }
  @media only screen and (min-width: 1200px) {
    width: 150px;
  }
  
  &:after {
    content: ' ';
    position: relative;
    width: 30px;
    height: 30px;
    @media only screen and (min-width: 744px) {
      width: 75px;
      height: 75px;
    }
    border-radius: 99999px;
    background-image:url('/images/scrollerArrow.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
`