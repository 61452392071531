import React from "react"
import styled from 'styled-components'
import { Grid } from '@components/atoms'
import { GridItem } from '@components/molecules'
import { GridSwitcherProps } from '@types'
import { GridItemWrapper } from '@components/molecules'
import { H1 } from '@components/atoms/BlockRenderer'
import { useGridHelper } from '@utils'


const GridSwitcher = ({
  layout: {
    layout,
  },
  gridItemTypeSwitch,
  className,
}: GridSwitcherProps & { className?: string }): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  if( !gridItemTypeSwitch.gridSwitcher ) return <></>  
  const {
    gridSwitcher: {
      switcherTitle,
      grids
    },
  } = gridItemTypeSwitch
  
  const [activeGrid, setActiveGrid] = React.useState(0)
  
  return (
    <GridItemWrapper 
      {...{ gridHelperEnabled, className }} 
      {...layout} 
    >
      <H1>{switcherTitle}</H1>
      <GridNav>
        {grids.map((grid, t) => (
          <NavItem key={`grid_title_${t}`} onClick={() => setActiveGrid(t)} active={activeGrid === t} >{grid.title}</NavItem>
        ))}
      </GridNav>
      <GridsWrapper>
        {grids.map((grid, i) => (
          <StyledGrid columns={layout?.columns || 12} mobileColumns={layout?.mobileColumns || 6} key={`grid_${i}`} first={i === 0} active={activeGrid === i}>
            {grid.gridItems.filter(p => !!p).map((item) => (
              <GridItem key={item._key} {...item} />
            ))}
          </StyledGrid>        
        ))}
      </GridsWrapper>
    </GridItemWrapper>
  )
}


const GridNav = styled.ul`
  display: block;
  margin-bottom: 1em;
`


const NavItem = styled.li<{
  active: boolean,
}>`
  display: inline-block;
  margin-right: 15px;
  color: ${props => props.active ? '#000000' : '#aaa'};
  &:hover {
    color: #000000;
  }
  transition: color 0.25s ease-in-out;
  cursor: pointer;
`


const GridsWrapper = styled.div`
  position: relative;
`


const StyledGrid = styled(props => <Grid {...props} />)<{
  columns: number,
  mobileColumns: number,
  first: boolean,
  active: boolean,
}>`
  width: 100%;
  position: ${props => props.first ? 'relative' : 'absolute'};
  top: 0;
  grid-template-columns: repeat(${props => props.mobileColumns}, 1fr);
  @media only screen and (min-width: 744px) {
    grid-template-columns: repeat(${props => props.columns}, 1fr);
  }
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity 0.25s ease-in-out;
`


export default GridSwitcher