import React from "react"
import styled from 'styled-components'
import { Picture, LinkMask } from '@components/atoms'
import { ChildrenWrapper } from './GridItem'
import { GridItemWrapper } from '@components/molecules'
import { GridItemImageFlipperProps } from '@types'
import { useGridHelper } from '@utils'
import useInterval from 'react-useinterval'


const GridItemImageFlipper = ({
  link,
  post,
  layout: {
    fullBleed,
    layout,
  },
  children,
  gridItemTypeSwitch,
  className,
}: { className?: string } & GridItemImageFlipperProps): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const {
    gridItemImageFlipper: {
      speed,
      direction,
      images,
    },
  } = gridItemTypeSwitch
  const [activeImage, setActiveImage] = React.useState(0)
  
  useInterval(() => {
    setActiveImage( activeImage === images.length - 1 ? 0 : activeImage + 1 )
  }, (speed || 1000))

  if( images.length < 1 ) return <></>  
  return (
    <StyledGridItemWrapper {...{ gridHelperEnabled, fullBleed, className }} {...layout} >
      <LinkMask {...{link, post}} />
      <HiddenImage src={images[0].asset.url} />
        
      {images.map((image, imageIndex) => (
        <StyledPicture src={image.asset.url} active={imageIndex === activeImage} {...{ direction }} key={`image_${imageIndex}`}/>
      ))}
        
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </StyledGridItemWrapper>
  )
}

const StyledGridItemWrapper = styled(props => <GridItemWrapper {...props} />)`
  line-height: 0;
  overflow: hidden;
`

const HiddenImage = styled.img`
  opacity: 0;
  width: 100%;
  height: auto;
`

interface StyledPictureProps {
  active: boolean
  direction?: 'up' | 'down' | 'left' | 'right'
}

const StyledPicture = styled(props => <Picture {...props} />).attrs(
  ({ active, direction }: StyledPictureProps) => {
  return { style: {
    transform: ((direction === 'left' || direction === 'right' || !direction ) ? 'translateX(' : 'translateY(')
    + (active ? 0 : (direction === 'left' || direction === 'up' || !direction ) ? -150 : 150)
    + '% )'
  } }}
)<StyledPictureProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.5s ease-in-out;
`




export default GridItemImageFlipper