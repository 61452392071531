import React from "react"
import styled from 'styled-components'
import { GridItemKenBurns } from '@components/molecules'
import { HeroProps } from './Hero'



const HeroKenBurns = ({
  heroType,
  heroImageConditional,
  heroVideoConditional,
  heroParallaxConditional,
}: HeroProps) => {
  if(!heroType || heroType === 'none') return <></>
  const bleed = (heroType === 'image') ? heroImageConditional!.bleed : (heroType === 'video') ? heroVideoConditional!.bleed : 'full bleed'
  
  return (
    <StyledGridItemKenBurns
      stripStyle="kenBurns"
      backgroundImage={heroImageConditional!.heroImage}
      stripItems={heroParallaxConditional!.gridItems} 
      {...{ bleed }} 
    />
  )
}



const StyledGridItemKenBurns = styled( props => <GridItemKenBurns {...props} />)<{ bleed: 'full bleed' | 'right bleed' }>`
  ${props => props.bleed === 'right bleed' && `
    margin-left: 20px;
    @media only screen and (min-width: 744px) {
      margin-left: calc(
        30px + 
        40px + 
        calc((100vw - 60px - 220px) * .16666)
      );
    }
    @media only screen and (min-width: 1152px) {
      margin-left: calc(
        40px + 
        40px + 
        calc((100vw - 80px - 220px) * .16666)      
      );
    }
    @media only screen and (min-width: 1440px) {
      margin-left: calc(
        2.775vw + 
        40px + 
        calc((100vw - 5.55vw - 220px) * .16666)
      );
    }
  `}
`



export default HeroKenBurns