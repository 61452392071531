import React from "react"
import styled from 'styled-components'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useWindowSize } from '@utils'


interface GridItemFXProps {
  hasFX?: boolean
  parallaxSpeed: number
  children: React.ReactElement
}

const GridItemFX = ({ hasFX, parallaxSpeed, children, ...props }: GridItemFXProps) => {
  const { width } = useWindowSize()
  if(!hasFX) return children
  
  const [mobile, setMobile] = React.useState(false)
  React.useEffect(() => setMobile(width < 750), [width])
  
  const [slideY, setSlideY] = React.useState(0)  
  useScrollPosition(
    ({ currPos }) => {
      setSlideY(currPos.y * parallaxSpeed * -.01)
    }, // effect callback
    [], // dependencies
    undefined, // position of element
    true, // use window instead of body.getBoundingClientRect
    5, // performance debounce
  )
  
  return <>
    <ContentWrapper {...{ slideY, mobile }} {...props} >
      {children}
    </ContentWrapper>
  </>
}


export const ContentWrapper = styled.div.attrs(
  ({ slideY, mobile }: {
    slideY?: number,
    mobile?: boolean
  }) => {
    let str = ''
    if(slideY) str += `translateY(${mobile ? slideY * 2 : slideY}vw) `
    return { style: {
      transform: str,
    } }
  }
)<{
  slideY?: number, 
}>`
  position: relative;
  line-height: 0;
  transform-origin: center center;
  will-change: transform;
`


export default GridItemFX