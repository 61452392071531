import React from 'react'
import styled from 'styled-components'
import { Picture } from '@components/atoms'
import { ShopH4, Psmall } from '@components/atoms/BlockRenderer'
import { QuantityWidget } from './'
import { LineItemProps } from '@types'



const Item = ({ id, quantity, variant }: LineItemProps) => {
  return (
    <Wrapper>
      <StyledPicture src={variant.image.src} />
      <CartItemData>
        <ItemLine>{variant.title}</ItemLine>
        <Psmall>${variant.price.toString().replace('.00', '')}</Psmall>
        <QuantityWidget {...{ id, quantity }} />
      </CartItemData>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px 40px;
  @media only screen and (min-width: 744px) {
    margin: 2vw 3vw;
  }
`


const ItemLine = styled(props => <ShopH4 {...props} />)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    display: block;
  }
`


const StyledPicture = styled(props => <Picture {...props} />)`
  width: 25vw;
  @media only screen and (min-width: 744px) {
    width: 120px;
  }
  margin-right: 20px;
`

const CartItemData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`


export default Item