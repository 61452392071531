import React from 'react'
import styled from 'styled-components'
import { useInViewport } from 'react-in-viewport'
import { palette } from '@theme'
import { getCdnPath } from '@utils'


const Video = ({autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, src, className}: VideoProps): React.ReactElement => {
  if(!src) return <></>

  const ref = React.useRef<HTMLVideoElement>(null)
  const { inViewport } = useInViewport( ref, { threshold: .15 } )
  
  return (
    <VideoTag
      {...{ autoPlay, muted, loop, hideIfMobile, hideIfDesktop, playsInline, ref }}
      className={`loadable ${className}`}
      visible={inViewport}
    >
      <source src={getCdnPath(src, 'files')} />
    </VideoTag>
  )
}



interface VideoTagProps { 
  rotation?: number
  hideIfDesktop?: boolean
  hideIfMobile?: boolean
  visible: boolean
}

const VideoTag = styled.video.attrs(
  ({ visible }: VideoTagProps) => {
  return { style: {
    opacity: visible ? 1 : 0,
  } }}
)<VideoTagProps>`
  background-color: ${palette.white};
  width: 100%;
  max-width: 100%;
  @media only screen and (max-width: 743px) {
    ${props => props.hideIfMobile && `display: none;`}
  } 
  transition: opacity 0.5s ease-in-out;
  @media only screen and (min-width: 744px) {
    ${props => props.hideIfDesktop && `display: none;`}
    transition: opacity 0.5s ease-in-out, transform 1.5s ease-in-out;
  }
`


type VideoProps = {
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  hideIfMobile?: boolean
  hideIfDesktop?: boolean
  playsInline?: boolean
  src: string
  className?: string
}


export default Video