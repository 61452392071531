import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { BackToTop, Grid, Link } from "@components/atoms"
import { Newsletter } from "@components/organisms"
import { H3 } from "@components/atoms/BlockRenderer"
import { palette } from "@theme"
import { GridHelperSwitch, useSocialLinkQuery } from "@utils"
import { SocialLink } from "@types"
import { useGridHelper } from "@utils"

const WrapperGap = styled.div<{ height?: number; hiddenGap?: boolean }>`
  display: none;
  @media only screen and (min-width: 750px) {
    display: ${props => (props.hiddenGap ? "none" : "block")};
    scroll-snap-align: start;
    pointer-events: none;
    height: ${props => props.height || 300}px;
  }
`

interface NavigationLink {
  label: string
  url: string
}

const Footer = ({
  hiddenFooter,
  hiddenGap,
  insideGrid,
}: {
  hiddenFooter?: boolean
  hiddenGap?: boolean
  insideGrid?: boolean
}): React.ReactElement => {
  const { gridHelperEnabled } = useGridHelper()
  const ref = React.useRef<HTMLDivElement>(null)
  const socialLinks: Array<SocialLink> = useSocialLinkQuery()
  const now = new Date()

  const {
    sanitySiteSettings: { footerNavigationLinks, footerNavigationLinksTwo },
  } = useStaticQuery(graphql`
    query FooterNavigationQuery {
      sanitySiteSettings {
        footerNavigationLinks {
          label
          url
        }
        footerNavigationLinksTwo {
          label
          url
        }
      }
    }
  `)

  return hiddenFooter ? (
    <></>
  ) : (
    <>
      <WrapperGap
        height={(ref.current?.offsetHeight || 1) - 1}
        {...{ hiddenGap }}
      />
      <Wrapper id="footer" {...{ ref, insideGrid, hiddenGap }}>
        <StyledGrid {...{ gridHelperEnabled }}>
          <BackToTop />

          <FooterNav className="nav1">
            {footerNavigationLinks.map((link: NavigationLink, i: number) => (
              <li key={`footer_link_${i}`}>
                <FooterLink to={link.url}>{link.label}</FooterLink>
              </li>
            ))}
          </FooterNav>

          <FooterNav className="nav2">
            {footerNavigationLinksTwo.map((link: NavigationLink, i: number) => (
              <li key={`footer_link_${i}`}>
                <FooterLink to={link.url}>{link.label}</FooterLink>
              </li>
            ))}
          </FooterNav>

          <Block columns={6} columnStart={1} desktopMargin className="contact">
            <BlockHeader>New Business Inquiries</BlockHeader>
            <BlockList>
              <li>Jolene Delisle, Founder</li>
              <li>
                <MailLink href="mailto:jolene@theworkingassembly.com ">
                  jolene@theworkingassembly.com
                </MailLink>
              </li>
            </BlockList>
          </Block>

          <Block columns={3} columnStart={7} desktopMargin className="address">
            <BlockHeader>Visit</BlockHeader>
            <BlockList>
              <li>6 West 18th Street, 12th Floor</li>
              <li>New York, NY 10011</li>
            </BlockList>
          </Block>

          <Block
            columns={3}
            columnStart={10}
            desktopMargin
            className="newsletter"
          >
            <BlockHeader>Friday Fives</BlockHeader>
            <Newsletter maskText="Sign up to our newsletter" />
          </Block>

          <OwnersBlock columns={3} columnStart={1} className="owners">
            <ul>
              <li>Minority Owned Certiﬁed by NMSDC</li>
              <li>Women Owned Certiﬁed by WBENC</li>
            </ul>
          </OwnersBlock>

          <Copyright columns={3} columnStart={4} className="copyright">
            &copy; {now.getFullYear()} The Working Assembly
            <br />
            All Rights Reserved
          </Copyright>

          <ConnectBlock columns={6} columnStart={7} className="social">
            {socialLinks.map((social: SocialLink, i: number) => (
              <li key={`social_${i}`}>
                <SocialA href={social.link} target="_blank">
                  {social.networkName}
                </SocialA>
              </li>
            ))}
          </ConnectBlock>

          <GridColumnWidth id="gridColumnWidth" />
        </StyledGrid>
      </Wrapper>
      {process.env.GATSBY_SANITY_WATCH_MODE && <StyledGridHelperSwitch />}
    </>
  )
}

const Wrapper = styled.footer<{ insideGrid?: boolean; hiddenGap?: boolean }>`
  background: ${props => (props.insideGrid ? "black" : "rgb(15, 15, 15)")};
  color: ${palette.white};

  padding: 65px 0px 50px 0px;

  @media only screen and (max-width: 749px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${props =>
      props.insideGrid &&
      `
      position: relative;
      grid-column: 1 / span 6;
    `}
  }

  @media only screen and (min-width: 750px) {
    ${props =>
      !props.insideGrid &&
      `
      position: fixed;
      z-index: 1;
      bottom: 0px;
      left: 0px;
      width: 100%;
      overflow: hidden;
    `}
    ${props =>
      props.insideGrid &&
      `
      position: relative;
      grid-column: 1 / span 12;
    `}
    padding: 60px 0px 40px 0px;
  }

  font-family: "Founders", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  font-size: 22px;
  @media only screen and (min-width: 835px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 26px;
  }
`

const StyledGrid = styled(props => <Grid {...props} />)<{
  gridHelperEnabled: boolean
}>`
  position: relative;
  margin: 0 auto;
  ${props =>
    props.gridHelperEnabled &&
    `
    > * {outline: 1px dashed green; }
  `}
  @media only screen and (max-width: 743px) {
    .backToTop {
      grid-area: 1 / 1 / span 1 / span 6;
      margin-bottom: 6vw;
    }
    #gridColumnWidth {
      grid-area: 2 / 1 / span 1 / span 1;
    }
    .contact {
      grid-area: 3 / 1 / span 1 / span 6;
      margin: 6vw 0;
    }
    .nav1 {
      grid-area: 4 / 1 / span 1 / span 3;
      margin: 6vw 0;
    }
    .nav2 {
      grid-area: 4 / 4 / span 1 / span 3;
      margin: 6vw 0;
    }
    .address {
      grid-area: 5 / 1 / span 1 / span 6;
      margin: 6vw 0;
    }
    .newsletter {
      grid-area: 6 / 1 / span 1 / span 6;
      margin: 6vw 0 4vw 0;
    }
    .social {
      grid-area: 7 / 1 / span 1 / span 6;
      margin: 20vw 0 4vw 0;
    }
    .owners {
      grid-area: 8 / 1 / span 1 / span 6;
      margin: 4vw 0;
    }
    .copyright {
      grid-area: 9 / 1 / span 1 / span 6;
      margin: 4vw 0 0 0;
    }
  }
  @media only screen and (min-width: 744px) and (max-width: 1099px) {
    .backToTop {
      grid-area: 1 / 1 / span 1 / span 3;
      margin: 4vw 0 0 0;
    }
    .contact {
      grid-area: 3 / 1 / span 1 / span 6;
      margin: 4vw 0;
    }
    .nav1 {
      grid-area: 1 / 5 / span 1 / span 8;
      margin: 4vw 0 0 0;
    }
    .nav2 {
      grid-area: 2 / 5 / span 1 / span 8;
      margin: 0 0 4vw 0;
    }
    .address {
      grid-area: 3 / 7 / span 1 / span 6;
      margin: 2vw 0;
    }
    .newsletter {
      grid-area: 4 / 1 / span 1 / span 6;
      margin: 2vw 0;
    }
    .social {
      grid-area: 4 / 7 / span 1 / span 6;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      margin: 2vw 0;
    }
    .owners {
      grid-area: 5 / 1 / span 1 / span 6;
      margin: 2vw 0;
    }
    .copyright {
      grid-area: 5 / 7 / span 1 / span 6;
      margin: 2vw 0;
    }
    #gridColumnWidth {
      grid-area: 2 / 1 / span 1 / span 1;
    }
  }
`

const FooterLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

const StyledGridHelperSwitch = styled(props => <GridHelperSwitch {...props} />)`
  position: fixed !important;
  z-index: 100000;
`

const BlockHeader = styled(props => <H3 {...props} />)`
  margin-bottom: 0.25em;
  @media only screen and (min-width: 835px) {
    margin-bottom: 0.5em;
  }
  color: rgb(148, 148, 148);
`

const BlockList = styled.ul`
  display: block;
`

const FooterNav = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  grid-column: span 6;
  line-height: 1.7em;
  @media only screen and (min-width: 744px) {
    grid-column: 7 / span 6;
  }
  li {
    display: block;
    @media only screen and (min-width: 744px) {
      display: inline-block;
      margin-right: 30px;
    }
  }
`

interface BlockProps {
  columns?: number
  columnStart?: number
  desktopMargin?: boolean
}

const Block = styled.div<BlockProps>`
  grid-column: span 6;
  @media only screen and (min-width: 744px) {
    grid-column: ${props => props.columnStart} / span ${props => props.columns};
    ${props =>
      props.desktopMargin &&
      `
      margin: 6vw 0;
    `}
  }
`

const ConnectBlock = styled(props => <Block {...props} />)`
  li {
    display: inline-block;
    margin-right: 15px;
  }
`

const OwnersBlock = styled(props => <Block {...props} />)`
  color: ${palette.chalice};
  font-family: "Founders", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  font-size: 16px;
  @media only screen and (min-width: 835px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
`

const Copyright = styled(props => <Block {...props} />)`
  color: ${palette.chalice};
  font-family: "Founders", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  font-size: 16px;
  @media only screen and (min-width: 835px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }
`

export const SocialA = styled.a<{ arrowVisible?: boolean }>`
  position: relative;
  margin-right: 15px;
  text-decoration: none;
  &:after {
    content: " ";
    position: absolute;
    top: 9px;
    right: -17px;
    width: 11px;
    height: 11px;
    background-image: url("/images/footer-hover-arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    opacity: ${props => (props.arrowVisible ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;
  }
  &:hover :after {
    opacity: 1;
  }
`

const MailLink = styled.a`
  text-decoration: none;
`

// "What the heck is this?"
// Glad you asked. The grid is dynamic (to screen width), and various function need to figure out what a single column width is.
// ex. a grid in a scroller that is wider than the window width; "12 fr" won't cut it.
// We're doing a big dumb "getElementById" to measure this.
const GridColumnWidth = styled.div`
  grid-column: 1 !important;
`

export default Footer
