import styled from 'styled-components'


export default styled.form<{
  sent: boolean,
  columns?: number
}>`
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media only screen and (min-width: 744px) {
    grid-column-start: 3;
    grid-column-end: span ${props => props.columns ? props.columns : 8};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  pointer-events: ${props => props.sent ? 'none' : 'all'};
  opacity: ${props => props.sent ? 0.25 : 1};
  transition: opacity 0.15s ease-in-out;
`


