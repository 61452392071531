import React from "react"
import styled from "styled-components"
import { palette } from "@theme"
import { validEmail } from "@utils"
import addToMailchimp from "gatsby-plugin-mailchimp"

const url =
  "https://theworkingassembly.us16.list-manage.com/subscribe/post-json?u=7ceb35a20d9136954c7300a62&id=69742a241a"

interface NewsletterProps {
  maskText?: string
  submitLabel?: string
}

const Newsletter = ({ maskText, submitLabel, ...props }: NewsletterProps) => {
  const inverted = false

  const [active, setActive] = React.useState(true)
  const [message, setMessage] = React.useState(maskText)
  const [email, setEmail] = React.useState("")
  const [disabled, setDisabled] = React.useState(true)
  React.useEffect(() => {
    setDisabled(!validEmail(email))
  }, [email])

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault()
    const data = await addToMailchimp(email)
    if (data.result === "error") {
      setMessage("Please check your email address and try again.")
      setActive(true)
    } else if (data.result === "success") {
      setMessage(data.msg)
      setActive(true)
      setEmail("")
    }
  }

  return (
    <Form action={url} method="GET" onSubmit={e => submitForm(e)} {...props}>
      <Mask {...{ active, inverted }}>{message}</Mask>
      <Row inlineSubmit={!!maskText}>
        <Input
          type="email"
          autoCapitalize="off"
          autoCorrect="off"
          name="MERGE0"
          placeholder="Email Address"
          {...{ active }}
          onFocus={() => setActive(false)}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Submit
          type="submit"
          name="submit"
          value={submitLabel ? submitLabel : "Ok"}
          {...{ disabled }}
        />
      </Row>
      <input
        type="hidden"
        name="ht"
        value="a41f343d29d49bb4f5f3afca5ec3ae49b00e0699:MTYzMjQyNTY4OS4zMDQ="
      />
      <input type="hidden" name="mc_signupsource" value="hosted" />
    </Form>
  )
}

const Mask = styled.div<{
  active: boolean
  inverted: boolean
}>`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  pointer-events: none;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.25s ease-in-out;
  background: ${props => (props.inverted ? palette.white : "rgb(15, 15, 15)")};
  color: ${props => (props.inverted ? palette.thunder : palette.white)};
`

export const Input = styled.input`
  appearance: none;
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  appearance: none;
  font-family: "Founders", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  background: transparent;
  color: white;
  padding: 0;
  font-size: 22px;
  border-radius: 0px;
  @media only screen and (min-width: 835px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 26px;
  }
  &::placeholder {
    color: ${palette.gray};
  }
`

const Form = styled.form`
  position: relative;
`

export const Submit = styled.input<{ disabled: boolean }>`
  font-family: "Founders", sans-serif;
  font-weight: 300;
  line-height: 1.2em;
  background: transparent;
  color: white;
  padding: 0;
  opacity: ${props => (props.disabled ? 0.25 : 1)};
  transition: opacity 0.15s ease-in-out;
  cursor: ${props => (props.disabled ? "not-allowed" : "auto")};
  font-size: 11px;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 15px;
  }
`

const Row = styled.div<{ inlineSubmit: boolean }>`
  display: ${props => (props.inlineSubmit ? "flex" : "block")};
  flex-direction: row;
`

export default Newsletter

/*
<Mailchimp
      action='https://<YOUR-USER>.us16.list-manage.com/subscribe/post?u=XXXXXXXXXXXXX&amp;id=XXXXXX'
      fields={[
        {
          name: 'MERGE0',
          placeholder: 'Your email',
          type: 'email',
          required: true
        },
        {
          name: 'MERGE0',
          placeholder: 'Your email',
          type: 'email',
          required: true
        },
        {
          name: 'u',
          value: '7ceb35a20d9136954c7300a62',
          type: 'hidden',
          required: true
        },
        {
          name: 'id',
          value: '69742a241a',
          type: 'hidden',
          required: true
        },
        {
          name: 'ht',
          value: 'a41f343d29d49bb4f5f3afca5ec3ae49b00e0699:MTYzMjQyNTY4OS4zMDQ=',
          type: 'email',
          required: true
        },
        {
          name: 'mc_signupsource',
          value: 'hosted:MTYzMjQyNTY4OS4zMDQ=',
          type: 'hidden',
          required: true
        },
      ]}
      messages = {
        {
          sending: "Sending...",
          success: "Thank you for subscribing!",
          error: "An unexpected internal error has occurred.",
          duplicate: "Too many subscribe attempts for this email address",
          button: "Ok"
        }
      }
    />
    */
