import React from 'react'

interface NavigationModeAction {
  type: 'setNavigationMode'
  payload: {
    navVisible: boolean
  }
}

interface MobileNavigationModeAction {
  type: 'setMobileNavigationMode'
  payload: {
    mobileNavVisible: boolean
  }  
}

type Dispatch = (action: NavigationModeAction | MobileNavigationModeAction) => void
interface State {
  navVisible: boolean
  mobileNavVisible: boolean
}

type NavigationModeProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  navVisible: true,
  mobileNavVisible: false,
}
const NavigationModeContext = React.createContext<State | undefined>(initialState)
const NavigationModeDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function navigationModeReducer(state: State, action: NavigationModeAction | MobileNavigationModeAction): State {
  if( action.payload === undefined ) return state
  switch (action.type) {
    case 'setNavigationMode': {
      return {
        ...state, 
        navVisible: action.payload.navVisible,
      }
    }
    case 'setMobileNavigationMode': {
      return {
        ...state, 
        mobileNavVisible: action.payload.mobileNavVisible,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

function NavigationModeProvider({children}: NavigationModeProviderProps) {
  const [state, dispatch] = React.useReducer(navigationModeReducer, initialState)
  return (
    <NavigationModeContext.Provider value={state}>
      <NavigationModeDispatchContext.Provider value={dispatch}>
        {children}
      </NavigationModeDispatchContext.Provider>
    </NavigationModeContext.Provider>
  )
}

function useNavigationMode() {
  const context = React.useContext(NavigationModeContext)
  if (context === undefined) {
    throw new Error('useNavigationMode must be used within a NavigationModeProvider')
  }
  return context
}

function useNavigationModeDispatch() {
  const context = React.useContext(NavigationModeDispatchContext)
  if (context === undefined) {
    throw new Error('useNavigationModeDispatch must be used within a NavigationModeProvider')
  }
  return context
}



export { NavigationModeProvider, useNavigationMode, useNavigationModeDispatch }