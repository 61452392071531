import { useState, useEffect } from "react";

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const updateMousePosition = (ev: { clientX: number, clientY: number }) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  }
  
  const updateTouchPosition = (ev: TouchEvent) => {
    setMousePosition({ x: ev.touches[0].screenX, y: ev.touches[0].screenY });
  }

  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition)
    window.addEventListener("touchmove", updateTouchPosition)
    // todo: add debounce?
    return () => {
      window.removeEventListener("mousemove", updateMousePosition)
      window.removeEventListener("touchmove", updateTouchPosition)
    }
  }, []);

  return mousePosition;
};

export default useMousePosition;