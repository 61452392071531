export const palette = {
  thunder: 'rgb(0, 0, 0)',
  teak: 'rgb(188, 154, 113)',
  gray: 'rgb(137, 128, 131)',
  chalice: 'rgb(172, 168, 169)',
  gallery: 'rgb(239, 239, 239)',
  white: 'rgb(255, 255, 255)',
}

export const effect = {
  magnify: `
    @media only screen and (min-width: 920px) {
      transition: transform 0.25s ease-in-out;
      transform-origin: center center;
      transform: scale(1.001);
      &:hover {
        transform: scale(1.025);
      }  
    }
  `,
}