// usage:   {process.env.GATSBY_SANITY_WATCH_MODE && (<GridHelper />)}

import React from 'react'
import styled from 'styled-components'
import { Margin, Grid } from '@components/atoms'
import { range } from 'lodash'
import { useGridHelperDispatch, useGridHelper } from './'


const GridHelperSwitch = ({className}: {className?: string}) => {
  if(process.env.NODE_ENV !== 'development') return <></>
  const dispatch = useGridHelperDispatch()
  const { gridHelperEnabled } = useGridHelper()
  return (
    <>
      <Wrapper {...{ className }}>
        <Label>
          <input
            type="checkbox" 
            name="gridHelperSwitch" 
            checked={gridHelperEnabled} 
            onChange={() => dispatch({type: 'toggle'})}
          />
          <span>grid helper</span>
        </Label>
        <ColorShiftMark {...{ gridHelperEnabled }} />
      </Wrapper>
      <GridHelperMargin className="gridHelper" {...{ gridHelperEnabled }} >
        <StyledGrid>
          {range(0, 12).map((_, i) => (
            <GridColumn key={`view_column_${i}`} hideMobile={(i > 5)} >{i + 1}</GridColumn>
          ))}
        </StyledGrid>
      </GridHelperMargin>
    </>
  )
}


const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  right: 5px;
  mix-blend-mode: difference;
  filter: invert(1);
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
`


const Label = styled.label`
  cursor: pointer;
  span {
    padding-left: 5px;
    color: black;
  }
`


const GridHelperMargin = styled(props => <Margin {...props} />)<{ gridHelperEnabled: boolean }>`
  display: ${props => props.gridHelperEnabled ? 'block' : 'none'};
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  outline: 5px solid red;
  box-sizing: border-box;
  pointer-events: none;
`

const ColorShiftMark = styled.div<{ gridHelperEnabled: boolean }>`
  display: none;
  ${props => props.gridHelperEnabled && `
    display: block;
    position: fixed;
    bottom: 35%;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px dashed green;
  `}
`


const StyledGrid = styled(props => <Grid {...props} />)`
  margin: auto;
  padding: 0;
`


const GridColumn = styled.div<{ hideMobile: boolean }>`
  @media only screen and (max-width: 743px) {
    display: ${props => props.hideMobile ? 'none' : 'flex'};
  }
  grid-column: span 1;
  display: flex;
  justify-content: flex-start; 
  flex-direction: column-reverse;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.1);
  text-align: center;
  font-size: 12px;
  color: blue;
`


export default GridHelperSwitch