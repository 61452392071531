import React from 'react'
import styled from 'styled-components'
import { validEmail } from '@utils'
import { TagText } from '@components/atoms/BlockRenderer'
import { ContactForm, Input, Submit, Message } from './'


interface FloatingContactFormProps {
  subject: string
  howText?: string
  hiddenInputs?: Array<string>
  children?: React.ReactNode
}


const FloatingContactForm = ({ subject, howText, hiddenInputs, children }: FloatingContactFormProps) => {
  const [sent, setSent] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [name, setName] = React.useState('')
  const [borough, setBorough] = React.useState('')
  const [website, setWebsite] = React.useState('')
  const [company, setCompany] = React.useState('')
  const [how, setHow] = React.useState('')
  const [message, setMessage] = React.useState('')

  const submit = async (e: React.FormEvent) => {
    e.preventDefault()
    await fetch('/api/contact', {
      method: 'POST',
      body: JSON.stringify({ subject, email, name, borough, website, company, how, product: "" }),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (response.status !== 200) {
        throw new Error("Bad response from server");
      }
      setMessage("Thanks for reaching out!")
      setSent(true)
    })
  }
  
  const [disabled, setDisabled] = React.useState(true)
  React.useEffect(() => {
      const fields = { name, borough, company, website, how }
      const pass = (Object.keys(fields) as Array<keyof typeof fields>)
                    .filter(x => !(hiddenInputs || []).includes(x))
                    .map(fieldKey => fields[fieldKey] !== '')
                    .reduce((passes, field) => passes && !!field, true)
    setDisabled(!pass || !validEmail(email))
  }, [email, name, borough, company, website, how])
  
  const [formVisible, setFormVisible] = React.useState(false)
  
  return (
    <>
      <FormTrigger onClick={() => setFormVisible(true)} >Apply Now</FormTrigger>
      <Onionskin onClick={() => setFormVisible(false)} {...{ formVisible }} />
      <Wrapper>
        <FloatingForm {...{ sent, formVisible }} method="POST" id="contactForm" >
          <FormHeader>{children}</FormHeader>
          <Input type="text" placeholder="Name" name="name" value={name} onChange={e => setName(e.target.value)} required filled={name.length > 0} />
          <Input type="text" placeholder="Company" name="company" value={company} onChange={e => setCompany(e.target.value)} required filled={company.length > 0} />
          <Input type="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} required filled={email.length > 0} />
          {!hiddenInputs?.includes('borough') && (
            <Input type="text" placeholder="Your Borough" name="borough" value={borough} onChange={e => setBorough(e.target.value)} required filled={borough.length > 0} />
          )}
          {!hiddenInputs?.includes('website') && (
            <Input type="text" placeholder="Website" name="website" value={website} onChange={e => setWebsite(e.target.value)} filled={website.length > 0} />
          )}
          {howText && (
            <Input type="text" placeholder={howText} name="how" value={how} onChange={e => setHow(e.target.value)} required twoCol filled={how.length > 0} />
          )}
          <Submit {...{ disabled }}>
            <input type="submit" value="Send" onClick={e => submit(e)} />
          </Submit>
          {message && <Message>{message}</Message>}
          <CloseButton onClick={() => setFormVisible(false)} />
        </FloatingForm>
      </Wrapper>
    </>
  )
}


const Onionskin = styled.div<{ formVisible: boolean }>`
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);

  opacity: ${props => props.formVisible ? 1 : 0};
  will-change: opacity;
  pointer-events: ${props => props.formVisible ? 'all' : 'none'};
  transition: opacity 0.35s ease-in-out;
`


const Wrapper = styled.div`
  position: fixed;
  z-index: 2001;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`


export const FloatingForm = styled(props => <ContactForm {...props} />)`
  width: calc(100vw - 20px);
  padding: 20px 15px;
  @media only screen and (min-width: 375px) {
    width: calc(100vw - 30px);
    padding: 30px 20px;
  }
  @media only screen and (min-width: 744px) {
    width: 700px;
    box-sizing: border-box;
    padding: 60px 50px;
  }

  opacity: ${props => props.formVisible ? 1 : 0};
  transform: scale(${props => props.formVisible ? 1 : 1.25});
  will-change: opacity, transform;
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  pointer-events: ${props => props.formVisible ? 'all' : 'none'};
  background-color: white;
  > * {
    opacity: ${props => props.sent ? 0.25 : 1};
    transition: opacity 0.15s ease-in-out;
  }
`


const FormTrigger = styled(props => <TagText {...props} />)<{ dark: boolean }>`
  position: fixed;
  z-index: 50;
  top: 24px;
  right: -1px;
  padding: 10px 15px;
  @media only screen and (min-width: 325px) {
    right: 20px;
    padding: 10px 25px;
  }
  @media only screen and (min-width: 744px) {
    top: 5vw;
    right: 30px;
    padding: 10px 40px;
  }
  @media only screen and (min-width: 1152px) {
    right: 40px;
  }
  @media only screen and (min-width: 1440px) {
    right: 2.775vw;
  }
  border: 1px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  opacity: 1;
  filter: invert(${props => props.dark ? 1 : 0});
  transition: filter 0.5s ease-in-out, opacity 0.15s ease-in-out;
`


const FormHeader = styled.div`
  @media only screen and (max-width: 374px) {
    > * {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 768px) {
    grid-column-end: span 2;
  }
  > * {
    color: #000000 !important;
  }
`


const CloseButton = styled.div`
  position: absolute;
  top: -25px;
  right: -25px;
  width: 50px;
  height: 50px;
  border-radius: 99px;
  background-color: black;
  cursor: pointer;
  &:after {
    content: ' ';
    position: absolute;
    width: 25px;
    height: 1px;
    top: 25px;
    left: 12.5px;
    transform-origin: center center;
    transform: rotateZ(45deg);
    background-color: white;
  }
  &:before {
    content: ' ';
    position: absolute;
    width: 25px;
    height: 1px;
    top: 25px;
    left: 12.5px;
    transform-origin: center center;
    transform: rotateZ(-45deg);
    background-color: white;
  }
`



export default FloatingContactForm